import React, { useEffect, useState } from "react";
import axios from 'axios';
import { user } from "../Config"
import Header from "../Contexts/Header";

const profil = (p) => {
    axios.get(user, Header()).then((response) => {
        //console.log(response.data)
        p(response.data)
    });
}


const Profile = () => {
    const [p, setp] = useState('')

    useEffect(() => {
        profil(setp)
    }, [])

    return (
        <div class='row  container-fluid p-0'>
            <div class='col fs-1' >
                <div class='container mt-4 py-5'>
                    <div class='row' >
                        <div class="card  col-md-sm p-0 col-lg-auto ms-1 me-1">
                            <div class="card-body">
                                <div class="d-flex flex-column align-items-center text-center">
                                    <img src="img/supercerame.jpg" alt="bg" className='m-2' style={{ width: '150px', height: '150px' }} />
                                    <div class="mt-3">
                                        <div class='fs-2 fw-bold text-secondary' ><img src="img/logonewj.png" alt="bg" className='m-2' style={{ width: '40px', height: '40px' }} />NewJoule</div>
                                        <p class="text-secondary mb-1 fs-5">Solution de monitoring</p>
                                        <p class="text-muted fs-6 ">Maroc , Casablanca ,Technopark
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm col-md-sm  ms-1 me-1 card mt-3 mt-lg-0 ">
                            <div class="row mt-3 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Nom</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder={p.last_name} />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Prénom</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder={p.first_name} />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Email</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control " id="colFormLabelLg" placeholder={p.email} />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Tel</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder="+21206........." />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Addresse</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder="Casablanca" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;































/**
 
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { user } from "../Config"


import { resetpass } from "../Config"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CgProfile } from 'react-icons/cg'
import Checkbox from '@mui/material/Checkbox';
import Header from "../Contexts/Header";
import Alert from '@mui/material/Alert';

const profil = (p) => {
    axios.get(user, Header()).then((response) => {
        //console.log(response.data)
        p(response.data)

    });
}



const Profile = () => {
    const [checked, setChecked] = React.useState(true);


    const [p, setp] = useState('')
    const [credentials, setCredentials] = useState({ email: "", password: "", newPass: "", confirmPass: "" })
    const [voirpass, setvoirpass] = useState(false)
    const [erreur, seterreur] = useState("")

    const handlchange = ({ currentTarget }) => {
        console.log(currentTarget)
        const { value, name } = currentTarget
        setCredentials({ ...credentials, [name]: value })
    }

    const handleChangeCheckBox = (event) => {
        setChecked(event.target.checked);

    };

    const handlsubmite = async (event) => {
        event.preventDefault();
        try {
            await axios.post(resetpass, credentials)
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    seterreur(data)
                    //window.localStorage.setItem('user', data.email)
                    //window.localStorage.setItem('token', data.token)
                    //axios.defaults.headers["x-access-token"] = data.token
                    //axios.defaults.headers["Authorization"] = data.token
                    //console.log(jwtDecode(data.token))
                    //setToken(data.token)-----
                    //console.log(isAuthenticated())
                    //isAuthenticated(setToken)

                })
        } catch (error) {

            console.log(error)
        }
    }

    useEffect(() => {
        profil(setp)
    }, [])

    useEffect(() => {
        seterreur("")
        const interval = setInterval(() => {
            seterreur("")
        }, 8000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div class='row  container-fluid p-0'>

            <div class='col fs-1' >

                <div class='container mt-4 py-5'>
                    <div class='row' >

                        <div class="card  col-md-sm p-0 col-lg-auto ms-1 me-1">
                            <div class="card-body">
                                <div class="d-flex flex-column align-items-center text-center">
                                    <CgProfile style={{ width: '150px', height: '150px' }} />
                                    <div class="mt-3">
                                        <div class='fs-1 fw-bold' >Jouléo</div>
                                        <p class="text-secondary mb-1 fs-4">Solution de monitoring</p>
                                        <p class="text-muted fs-6 ">Maroc , Casablanca ,Technopark
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm col-md-sm  ms-1 me-1 card mt-3 mt-lg-0 ">
                            <div class="row mt-3 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Nom</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder={p.last_name} />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Prénom</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder={p.first_name} />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Email</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control " id="colFormLabelLg" placeholder={p.email} />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Tel</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder="+21206........." />
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Addresse</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="colFormLabelLg" placeholder="Casablanca" />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container col-sm col-md-sm card  mt-4 ">

                        <Checkbox className="position-absolute top-0 start-0 translate "
                            checked={checked}
                            onChange={handleChangeCheckBox}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                        <div class='col  mt-2 mb-2 text-center'  >
                            <form onSubmit={handlsubmite} >
                                <div className='mb-4 mt-4'>
                                    <TextField name="email" id="email" label="User" variant="standard" fullWidth onChange={handlchange} disabled={checked} />
                                </div>
                                <div className='mt-2 '>
                                    <span className='d-flex align-items-center  justify-content-center'>
                                        <TextField name="password"
                                            id="exampleInputPassword1"
                                            label="Password"
                                            variant={"standard"}
                                            type={voirpass ? 'text' : 'password'}
                                            autoComplete="current-password"
                                            fullWidth onChange={handlchange}
                                            disabled={checked}
                                        />
                                        <span onClick={() => { setvoirpass(!voirpass) }} >{voirpass ? <VisibilityIcon /> : <VisibilityOffIcon />} </span>
                                    </span>

                                </div>
                                <div className='mt-2'>
                                    <span className='d-flex align-items-center  justify-content-center'>
                                        <TextField name="newPass"
                                            id="exampleInputPassword11"
                                            label="New password"
                                            variant={"standard"}
                                            type={voirpass ? 'text' : 'password'}
                                            autoComplete="current-password"
                                            fullWidth onChange={handlchange}
                                            disabled={checked}
                                        />

                                    </span>

                                </div>

                                <div className='mt-2'>
                                    <span className='d-flex align-items-center  justify-content-center'>
                                        <TextField name="confirmPass"
                                            id="exampleInputPassword111"
                                            label="Confirm password"
                                            variant={"standard"}
                                            type={voirpass ? 'text' : 'password'}
                                            autoComplete="current-password"
                                            fullWidth onChange={handlchange}
                                            disabled={checked}
                                        />

                                    </span>

                                </div>
                                <div className='container text-center mt-4 text-danger fs-6'>{
                                    erreur === "votre mot de passe a été modifié avec succès" ?
                                        <Alert severity="success">{erreur}</Alert> :
                                        erreur === "" ? "":
                                        <Alert severity="error">{erreur}</Alert>}
                                        </div>
                                <Button variant="contained" color='info' endIcon={<EditIcon />} type="submit" fullWidth className='mt-3' disabled={checked} >
                                    Modifier
                                </Button>

                            </form>

                        </div>
                    </div>

                </div>



            </div>


        </div>
    );
}

export default Profile;
 */