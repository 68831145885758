//import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Page/Home';
import Profile from './Page/Profile';
import Alertes from './Page/Alertes';
import Parametre from './Page/Parametre';
import Aide from './Page/Aide';
import Authentification from './Page/Authentification';
import ProtectedRoute from './Contexts/ProtectedRoute';
import Dashbord from './Page/Dashbord';
import SCHNEIDER_1 from './Page/Compteurs/SCHNEIDER_1';
import SCHNEIDER_2 from './Page/Compteurs/SCHNEIDER_2';
import SCHNEIDER_3 from './Page/Compteurs/SCHNEIDER_3';
import SCHNEIDER_4 from './Page/Compteurs/SCHNEIDER_4';
import SCHNEIDER_5 from './Page/Compteurs/SCHNEIDER_5';
import SCHNEIDER_6 from './Page/Compteurs/SCHNEIDER_6';
import SCHNEIDER_7 from './Page/Compteurs/SCHNEIDER_7';
import SCHNEIDER_8 from './Page/Compteurs/SCHNEIDER_8';
import SCHNEIDER_9 from './Page/Compteurs/SCHNEIDER_9';
import SCHNEIDER_10 from './Page/Compteurs/SCHNEIDER_10';
import SCHNEIDER_11 from './Page/Compteurs/SCHNEIDER_11';
import SCHNEIDER_12 from './Page/Compteurs/SCHNEIDER_12';
import SCHNEIDER_13 from './Page/Compteurs/SCHNEIDER_13';


function App() {
/*
  const token = window.localStorage.getItem('token')
 
  if (!window.localStorage.getItem('token')) {
    window.localStorage.setItem('token', tok)
  }

  */

  return (

    <div className="app" >
      <BrowserRouter>
        <Routes>
          <Route path="/authentification" element={<Authentification />} />
          <Route element={<ProtectedRoute/> }>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/dashbord" element={<Dashbord />} />
            <Route path="/Alert" element={<Alertes />} />
            
            <Route path="/profile" element={<Profile />} />
            <Route path="/parametre" element={<Parametre />} />
            <Route path="/aide" element={<Aide />} />

            <Route path="/Schneider_1" element={<SCHNEIDER_1/>} />
            <Route path="/Schneider_2" element={<SCHNEIDER_2/> } />
            <Route path="/Schneider_3" element={<SCHNEIDER_3/> } />
            <Route path="/Schneider_4" element={<SCHNEIDER_4/> } />
            <Route path="/Schneider_5" element={<SCHNEIDER_5/> } />
            <Route path="/Schneider_6" element={<SCHNEIDER_6/> } />
            <Route path="/Schneider_7" element={<SCHNEIDER_7/> } />
            <Route path="/Schneider_8" element={<SCHNEIDER_8/> } />
            <Route path="/Schneider_9" element={<SCHNEIDER_9/> } />
            <Route path="/Schneider_10" element={<SCHNEIDER_10/> } />
            <Route path="/Schneider_11" element={<SCHNEIDER_11/> } />
            <Route path="/Schneider_12" element={<SCHNEIDER_12/> } />
            <Route path="/Schneider_13" element={<SCHNEIDER_13/> } />


          </Route>
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


/*
import { BrowserRouter, Routes, Route } from 'react-router-dom'
   <BrowserRouter>
        <Routes>
          <Route path="/" element={<NavBar />} />
        </Routes>
      </BrowserRouter>
 */