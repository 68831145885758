import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import axios from 'axios';
//import { Button } from 'react-bootstrap'
//import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types';
import { URL_LOGIN } from "../Config"
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Authentification = ({ setToken }) => {
    const [credentials, setCredentials] = useState({ email: "", password: "" })
    const [erreur, seterreur] = useState("")
    const [voirpass, setvoirpass] = useState(false)


    const handlchange = ({ currentTarget }) => {
        console.log(currentTarget)
        const { value, name } = currentTarget
        setCredentials({ ...credentials, [name]: value })
    }

    const handlsubmite = async (event) => {
        event.preventDefault();
        try {
            await axios.post(URL_LOGIN, credentials)
                .then(response => response.data)
                .then(data => {
                    window.localStorage.setItem('user', data.email)
                    window.localStorage.setItem('token', data.token)
                    axios.defaults.headers["x-access-token"] = data.token
                    //axios.defaults.headers["Authorization"] = data.token
                    //console.log(jwtDecode(data.token))
                    setToken(data.token)
                    //console.log(isAuthenticated())
                    //isAuthenticated(setToken)

                })
        } catch (error) {
            seterreur("User ou password invalide *")
            console.log(error)
        }
    }


    useEffect(() => {
        seterreur("")
        const interval = setInterval(() => {
            seterreur("")
        }, 7000);
        return () => {
            clearInterval(interval)
        }
    }, [])


    /*
     const isAuthenticated = (setToken) => {
         const token = window.localStorage.getItem('token')
         const { exp} = jwtDecode(token)
         if (token) {
             if (exp * 1000 > new Date().getTime()) {
                 setToken(token)
                 //return true
             } 
         }
     }
   */
    //isAuthenticated()


    return (
        <div class='container '>

            <div class='col  d-flex align-items-center justify-content-center mt-5 text-center' >
                <form onSubmit={handlsubmite} >
                    <img src="img/login.jpg" alt="bg" fluid />
                    <div className='mb-4 mt-4'>
                        <TextField name="email" id="email" label="User" variant="standard" fullWidth onChange={handlchange} />
                    </div>
                    <div className='mt-2'>
                        <span className='d-flex align-items-center  justify-content-center'>
                            <TextField name="password"
                                id="exampleInputPassword1"
                                label="Password"
                                variant={"standard"}
                                type={voirpass ? 'text' : 'password'}
                                autoComplete="current-password"
                                fullWidth onChange={handlchange} />
                            <span onClick={() => { setvoirpass(!voirpass) }} >{voirpass ? <VisibilityIcon /> : <VisibilityOffIcon />} </span>
                        </span>

                    </div>
                    <div className='container text-center mt-4 text-danger'>{erreur === "" ? "" : <Alert severity="error">{erreur}</Alert>}</div>
                    
                        <Button variant="contained" color='secondary' endIcon={<LoginIcon />} type="submit" fullWidth className='mt-3'>
                            login
                        </Button>
                   

                </form>

            </div>
            <div className='mt-5 pt-5 d-flex align-items-center justify-content-center mb-5'>
            <span><img src="img/logonewj.png" alt="bg" fluid  style={{width:"55px",height:"55px"}}/> <img src="img/supercerame.jpg" alt="bg" fluid style={{width:"100px",height:"100px"}} /></span>

            </div>

        </div>
    );
}

export default Authentification;

Authentification.propTypes = {
    setToken: PropTypes.func.isRequired
}












/*



            <form onSubmit={handlsubmite} >
                <div class="form-group mb-2 col-5">
                    <label for="exampleInputEmail1">Email address</label>
                    <input type="email" name="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" onChange={handlchange}></input>
                    <small id="emailHelp" class="form-text text-muted">Nous ne partagerons jamais votre e-mail avec quelqu'un d'autre</small>
                </div>
                <div class="form-group col-5">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" name="password" class="form-control" id="exampleInputPassword1" placeholder="Password" onChange={handlchange}></input>
                </div>
                <div class="form-check mt-4 mb-2 col-5">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1"></input>
                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                </div>
                <Button type="submit" class="btn btn-primary">S'identifier</Button>
            </form>





            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField id="standard-basic" label="User" variant="standard" />
                    <TextField id="standard-basic" label="Password" variant="standard" type="password" autoComplete="current-password" />
                </div>

                <Button variant="contained" endIcon={<SendIcon />}>
                    Send
                </Button>
            </Box>



*/