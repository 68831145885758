import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Profile from '../Page/Profile';
import Parametre from '../Page/Parametre';
import Aide from '../Page/Aide';
import Alertes from '../Page/Alertes';
import Dashbord from '../Page/Dashbord';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import SCHNEIDER_1 from '../Page/Compteurs/SCHNEIDER_1';
import SCHNEIDER_2 from '../Page/Compteurs/SCHNEIDER_2';
import SCHNEIDER_3 from '../Page/Compteurs/SCHNEIDER_3';
import SCHNEIDER_4 from '../Page/Compteurs/SCHNEIDER_4';
import SCHNEIDER_5 from '../Page/Compteurs/SCHNEIDER_5';
import SCHNEIDER_6 from '../Page/Compteurs/SCHNEIDER_6';
import SCHNEIDER_7 from '../Page/Compteurs/SCHNEIDER_7';
import SCHNEIDER_8 from '../Page/Compteurs/SCHNEIDER_8';
import SCHNEIDER_9 from '../Page/Compteurs/SCHNEIDER_9';
import SCHNEIDER_10 from '../Page/Compteurs/SCHNEIDER_10';
import SCHNEIDER_11 from '../Page/Compteurs/SCHNEIDER_11';
import SCHNEIDER_12 from '../Page/Compteurs/SCHNEIDER_12';
import SCHNEIDER_13 from '../Page/Compteurs/SCHNEIDER_13';


import AdUnitsIcon from '@mui/icons-material/AdUnits';
const drawerWidth = 240;

const village = {
    'Home':'',
    'Alertes':'',
    'Profil':'',
    'Parametre':'',
    'Aide':'',
    'Schneider_1': ' - TGBT_8 cuisson / presse SCK_8 ',
    'Schneider_2': ' - TGBT_11',
    'Schneider_3': ' - TGBT_12',
    'Schneider_4': ' - TGBT_2 : Atomiseur P1_P2',
    'Schneider_5': ' - TGBT_2 : Atomiseur P1_P2',
    'Schneider_6': ' - TGBT_4 : Rechauffeur  gaz + compresseur + eclairage + station de pompage',
    'Schneider_7': ' - TGBT_8 : Atomiseur_8 + compresseur_9',
    'Schneider_8': ' - TGBT_1 : Broyeur continu',
    'Schneider_9': ' - SCK_9 + PDM_9 + LEM + cuisson',
    'Schneider_10': ' - SCK_9 + PDM_9 + LEM + cuisson',
    'Schneider_11': ' - TGBT_4 : Rechauffeur  gaz + compresseur + eclairage + station de pompage',
    'Schneider_12': ' - TGBT_1 : Broyeur continu',
    'Schneider_13': ' - TGBT_4 : Rechauffeur  gaz + compresseur + eclairage + station de pompage',
    
}


function ResponsiveDrawer(props) {
    const { window } = props;
    //const {change} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [element, setelement] = React.useState("Home");
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className='d-flex align-items-center py-1'>
                <img src="img/logonewj.png" alt="bg" className='m-2' style={{ width: '40px', height: '40px' }} />
                <span className='fw-bold fs-3 text-secondary' >NewJoule</span>
            </div>

            <Divider />
            <List className='mb-3 mt-2 ' >
                {['Home', 'Alertes', 'Schneider_1', 'Schneider_2', 'Schneider_3', 'Schneider_4', 'Schneider_5'
                    , 'Schneider_6', 'Schneider_7', 'Schneider_8', 'Schneider_9', 'Schneider_10', 'Schneider_11'
                    , 'Schneider_12', 'Schneider_13'].map((text, index) => (
                        <ListItem key={text} disablePadding  >
                            <ListItemButton onClick={(e) => { e.preventDefault(); setelement(text) }}>
                                <ListItemIcon>
                                    {index === 0 ? <HomeIcon className='fs-3' /> :
                                        index === 1 ? <AddAlertIcon className='fs-3' /> :
                                            <AdUnitsIcon />}

                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}

            </List>
            <Divider />

            <List>
                {['Profil', 'Parametre', 'Aide'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton onClick={(e) => { e.preventDefault(); setelement(text) }} >
                            <ListItemIcon>
                                {index === 0 ? <AccountCircleIcon className='fs-3' /> : index === 1 ? <SettingsSuggestIcon className='fs-3' /> : <HelpOutlineIcon className='fs-3' />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Link class='lien' to="/authentification">
                <Button variant="contained"
                    endIcon={<LogoutIcon />}
                    className=' bottom ms-4 mb-4 d-flex justify-contente'
                    onClick={() => { localStorage.removeItem("token") }}
                >
                    Déconnecter
                </Button>
            </Link>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {element +village[element]}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />

                <Typography paragraph>

                    {
                        element === "Alertes" ? <Alertes /> :
                        element === "Profil" ? <Profile /> :
                        element === "Parametre" ? <Parametre /> :
                        element === "Aide" ? <Aide /> :
                        element === "Schneider_1" ? <SCHNEIDER_1 /> :
                        element === "Schneider_2" ? <SCHNEIDER_2 /> :
                        element === "Schneider_3" ? <SCHNEIDER_3 /> :
                        element === "Schneider_4" ? <SCHNEIDER_4 /> :
                        element === "Schneider_5" ? <SCHNEIDER_5 /> :
                        element === "Schneider_6" ? <SCHNEIDER_6 /> :
                        element === "Schneider_7" ? <SCHNEIDER_7 /> :
                        element === "Schneider_8" ? <SCHNEIDER_8 /> :
                        element === "Schneider_9" ? <SCHNEIDER_9 /> :
                        element === "Schneider_10" ? <SCHNEIDER_10 /> :
                        element === "Schneider_11" ? <SCHNEIDER_11 /> :
                        element === "Schneider_12" ? <SCHNEIDER_12 /> :
                        element === "Schneider_13" ? <SCHNEIDER_13 /> :
                        <Dashbord />
                    }

                </Typography>
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;














/*

import "./SideBar.scss" 
import "bootstrap/dist/css/bootstrap.min.css"
import React from "react";
import Alert from "../Page/Alert";
import Button from '@mui/material/Button';

const SideBar = () => {
    return (
        <div class="wrapper">
            <nav id="sidebar">
                <div class="sidebar-header">
                    <h3>Newjoule</h3>
                </div>

                <ul class="list-unstyled components">
                    <li class="active">
                        <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                            <i class="fas fa-home"></i>
                            Home
                        </a>
                        
                    </li>
                    <li>
                        <a href="#">
                            <i class="fas fa-briefcase"></i>
                            About
                        </a>
                        <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                            <i class="fas fa-copy"></i>
                            Pages
                        </a>
                        <ul class="collapse list-unstyled" id="pageSubmenu">
                            <li>
                                <a href="#">Page 1</a>
                            </li>
                            <li>
                                <a href="#">Page 2</a>
                            </li>
                            <li>
                                <a href="#">Page 3</a>
                            </li>
                        </ul>
                    </li>
                </ul>

            </nav>


            <div id="content">

                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container-fluid">

                        <button type="button" id="sidebarCollapse" class="btn btn-info">
                            <i class="fas fa-align-left"></i>
                            <span>Toggle Sidebar</span>

                        </button>
                        <Alert />
                    </div>
                    <Button variant="contained">Hello World</Button>

                </nav>
            </div>
        </div>
    );
}

export default SideBar;


*/




















