
const Aide = () => {

    return (
        <div className="text-center row  p-0" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <img src="img/logonewj.png" alt="bg" style={{ width: '500px', height: '500px' }} className="p-0" />
            <a href="https://www.newjoule.com/">
                <div className='fw-bold fs-3 text-secondary p-0' >NewJoule</div>
            </a>
        </div>
    );
}

export default Aide;