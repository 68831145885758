import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { AiFillPieChart, } from "react-icons/ai";
import {
    activePower, apparenteEnergy,
    apparentePower,
    courant,
    homePageInfo,
    reactivePower,
    energie_jour,
    energie_nuit,
    puissanceMinMaxMoyJour,
    energieMinMaxMoyJour,
    energieMinMaxMoyMois,
    energie_heureCreuse,
    energie_heurePointe,
    energie_heurePleine,
    energie_heureSuperPointe
} from '../../Contexts/Fonction';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from 'react-bootstrap/Table';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,

}));
//import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
//import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
//import Badge from '@mui/material/Badge';
//import { styled } from '@mui/material/styles';
//import IconButton from '@mui/material/IconButton';


const SCHNEIDER_7 = () => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const yesterdayISO = yesterday.toISOString();
    //--
    const onemonth = new Date();
    onemonth.setDate(today.getDate() - 30);
    const onemonthISO = onemonth.toISOString();

    const [startDatetrache, setStartDatetrache] = useState(onemonthISO);
    const [stopDatetrache, setStopDatetrache] = useState(new Date());

    const [heurePointe, setheurePointe] = useState([]);
    const [heurePleine, setheurePleine] = useState([]);
    const [heureCreuse, setheureCreuse] = useState([]);
    const [heureSuperPointe, setheureSuperPointe] = useState([]);

    //--

    const [startDate, setStartDate] = useState(yesterdayISO);
    const [stopDate, setStopDate] = useState(new Date());

    const [startDateActivePower, setStartDateActivePower] = useState(yesterdayISO);
    const [stopDateActivePower, setStopDateActivePower] = useState(new Date());

    const [startDateReactivePower, setStartDateReactivePower] = useState(yesterdayISO);
    const [stopDateReactivePower, setStopDateReactivePower] = useState(new Date());

    const [startDateApparentePower, setStartDateApparentePower] = useState(yesterdayISO);
    const [stopDateApparentePower, setStopDateApparentePower] = useState(new Date());


    const [IdCompteur, setIdCompteur] = React.useState(`7`)
    const [scheider_1, setscheider_1] = useState([]);

    const [EnergiesJour, setEnergiesJour] = useState([]);
    const [EnergiesNuit, setEnergiesNuit] = useState([]);


    const [date, setdate] = useState([]);
    const [activePower1, setactivePower1] = useState([]);
    const [activePower2, setactivePower2] = useState([]);
    const [activePower3, setactivePower3] = useState([]);

    const [dateReactivePower, setdateReactivePower] = useState([]);
    const [reactivePower1, setreactivePower1] = useState([]);
    const [reactivePower2, setreactivePower2] = useState([]);
    const [reactivePower3, setreactivePower3] = useState([]);

    const [dateApparentePower, setdateApparentePower] = useState([]);
    const [apparentePower1, setapparentePower1] = useState([]);
    const [apparentePower2, setapparentePower2] = useState([]);
    const [apparentePower3, setapparentePower3] = useState([]);

    const [dateEnergy, setdateEnergy] = useState([]);
    const [energyP, setenergyP] = useState([]);

    const [dateCourant, setdateCourant] = useState([]);
    const [courant_1, setCourant1] = useState([]);
    const [courant_2, setCourant2] = useState([]);
    const [courant_3, setCourant3] = useState([]);

    const [PminMaxMoyJour, setPminMaxMoyJour] = useState([]);
    const [EminMaxMoyJour, setEminMaxMoyJour] = useState([]);
    const [EminMaxMoyMois, setEminMaxMoyMois] = useState([]);

    const [value, setValue] = React.useState(`30`);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [Energie, setEnergie] = React.useState(`180`);
    const handleChangeEnergie = (event, newValue) => {
        setEnergie(newValue);
    };

    /*
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -3,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));
*/

    useEffect(() => {
        energie_jour(setEnergiesJour, IdCompteur, value)
        energie_nuit(setEnergiesNuit, IdCompteur, value)
        //courant(setdate_courant, setcourant_L1, setcourant_L2, setcourant_L3, valueCourant)
        const interval = setInterval(() => {
            energie_jour(setEnergiesJour, IdCompteur, value)
            energie_nuit(setEnergiesNuit, IdCompteur, value)
            //courant(setdate_courant, setcourant_L1, setcourant_L2, setcourant_L3, valueCourant)
        }, 300000); //5min
        return () => {
            clearInterval(interval)
        }
    }, [value])

    useEffect(() => {
        homePageInfo(setscheider_1, IdCompteur)
        const interval = setInterval(() => {
            homePageInfo(setscheider_1, IdCompteur)

        }, 300000); //5min
        return () => {
            clearInterval(interval)
        }
    }, [])



    useEffect(() => {
        activePower(setdate, setactivePower1, setactivePower2, setactivePower3, IdCompteur, startDateActivePower, stopDateActivePower)
        reactivePower(setdateReactivePower, setreactivePower1, setreactivePower2, setreactivePower3, IdCompteur, startDateReactivePower, stopDateReactivePower)
        apparentePower(setdateApparentePower, setapparentePower1, setapparentePower2, setapparentePower3, IdCompteur, startDateApparentePower, stopDateApparentePower)
        apparenteEnergy(setdateEnergy, setenergyP, IdCompteur, Energie)
        courant(setdateCourant, setCourant1, setCourant2, setCourant3, IdCompteur, startDate, stopDate)
        puissanceMinMaxMoyJour(setPminMaxMoyJour, IdCompteur, `1`)
        energieMinMaxMoyJour(setEminMaxMoyJour, IdCompteur, `1`)
        energieMinMaxMoyMois(setEminMaxMoyMois, IdCompteur, `30`)

        energie_heurePointe(setheurePointe, IdCompteur, startDatetrache, stopDatetrache)
        energie_heurePleine(setheurePleine, IdCompteur, startDatetrache, stopDatetrache)
        energie_heureCreuse(setheureCreuse, IdCompteur, startDatetrache, stopDatetrache)
        energie_heureSuperPointe(setheureSuperPointe, IdCompteur, startDatetrache, stopDatetrache)


        const interval = setInterval(() => {
            activePower(setdate, setactivePower1, setactivePower2, setactivePower3, IdCompteur, startDateActivePower, stopDateActivePower)
            reactivePower(setdateReactivePower, setreactivePower1, setreactivePower2, setreactivePower3, IdCompteur, startDateReactivePower, stopDateReactivePower)
            apparentePower(setdateApparentePower, setapparentePower1, setapparentePower2, setapparentePower3, IdCompteur, startDateApparentePower, stopDateApparentePower)
            apparenteEnergy(setdateEnergy, setenergyP, IdCompteur, Energie)
            courant(setdateCourant, setCourant1, setCourant2, setCourant3, IdCompteur, startDate, stopDate)
            puissanceMinMaxMoyJour(setPminMaxMoyJour, IdCompteur, `1`)
            energieMinMaxMoyJour(setEminMaxMoyJour, IdCompteur, `1`)
            energieMinMaxMoyMois(setEminMaxMoyMois, IdCompteur, `30`)

            energie_heurePointe(setheurePointe, IdCompteur, startDatetrache, stopDatetrache)
            energie_heurePleine(setheurePleine, IdCompteur, startDatetrache, stopDatetrache)
            energie_heureCreuse(setheureCreuse, IdCompteur, startDatetrache, stopDatetrache)
            energie_heureSuperPointe(setheureSuperPointe, IdCompteur, startDatetrache, stopDatetrache)

        }, 300000); //5min
        return () => {
            clearInterval(interval)
        }
    }, [Energie,startDate,stopDate,startDateActivePower,stopDateActivePower,startDateReactivePower,stopDateReactivePower,startDateApparentePower,stopDateApparentePower,startDatetrache,stopDatetrache])


    return (
        <div className='container-flex' >

            <div class="row justify-content-md-center">
                <div class="shadow bg-body rounded   m-2 col-lg col-md-lg  p-0 d-flex justify-content " style={{ minWidth: '600px' }}>
                    <div className='row container mt-1'>
                        <div style={{ background: "#3489eb" }} className='card border-primary  text-white text-center ms-2 mt-2 fw-bold'>Valeurs instantanées  </div>
                        <div class='row  px-1 d-flex justify-content-center mt-4 '>
                            <div class='col-lg col-md-lg  forme1 d-flex px-0 mt-1 justify-content-center'>
                                <span class=''>
                                    <Chart
                                        options={
                                            {
                                                chart: {
                                                    type: 'radialBar',
                                                    offsetY: -12,
                                                    sparkline: {
                                                        enabled: true
                                                    }
                                                },
                                                colors: ['#fc0362'],
                                                plotOptions: {
                                                    radialBar: {
                                                        hollow: {
                                                            margin: 15,
                                                            size: "50%"
                                                        },

                                                        dataLabels: {
                                                            showOn: "always",
                                                            name: {
                                                                offsetY: 5,
                                                                show: true,
                                                                fontSize: "14px"
                                                            },
                                                            value: {
                                                                fontSize: "0px",
                                                                show: true
                                                            }
                                                        }
                                                    }
                                                },

                                                stroke: {
                                                    lineCap: "round",
                                                },
                                                labels: ['V1'],

                                            }
                                        }

                                        series={[Number(scheider_1?.voltage_v1).toFixed(2) * 100 / 400]}
                                        type="radialBar"
                                        width="60px"
                                        height="60px"
                                    // width="500"
                                    />
                                </span>
                                <span class='text-center py-1' style={{ width: '30%' }}>
                                    <span className='fw-bold' style={{ fontSize: '14px' }}>{Number(scheider_1?.voltage_v1).toFixed(2)} V</span>
                                </span>
                            </div>
                            <div class='col-lg col-md-lg forme1 d-flex px-0 mt-1 justify-content-center'>
                                <span class=''>
                                    <Chart
                                        options={
                                            {
                                                chart: {
                                                    type: 'radialBar',
                                                    offsetY: -12,
                                                    sparkline: {
                                                        enabled: true
                                                    }
                                                },
                                                colors: ['#079220'],
                                                plotOptions: {
                                                    radialBar: {
                                                        hollow: {
                                                            margin: 15,
                                                            size: "50%"
                                                        },

                                                        dataLabels: {
                                                            showOn: "always",
                                                            name: {
                                                                offsetY: 5,
                                                                show: true,
                                                                fontSize: "14px"
                                                            },
                                                            value: {
                                                                fontSize: "0px",
                                                                show: true
                                                            }
                                                        }
                                                    }
                                                },

                                                stroke: {
                                                    lineCap: "round",
                                                },
                                                labels: ['V2'],

                                            }
                                        }

                                        series={[Number(scheider_1?.voltage_v2).toFixed(2) * 100 / 400]}
                                        type="radialBar"
                                        width="60px"
                                        height="60px"
                                    // width="500"
                                    />
                                </span>
                                <span class='text-center py-1' style={{ width: '30%' }}>
                                    <span className='fw-bold' style={{ fontSize: '14px' }} >{Number(scheider_1?.voltage_v2).toFixed(2)} V</span>
                                </span>
                            </div>
                            <div class='col-lg col-md-lg forme1 d-flex px-0 mt-1 justify-content-center'>
                                <span class=''>
                                    <Chart
                                        options={
                                            {
                                                chart: {
                                                    type: 'radialBar',
                                                    offsetY: -12,
                                                    sparkline: {
                                                        enabled: true
                                                    }
                                                },
                                                colors: ['#0398fc'],
                                                plotOptions: {
                                                    radialBar: {
                                                        hollow: {
                                                            margin: 15,
                                                            size: "50%"
                                                        },

                                                        dataLabels: {
                                                            showOn: "always",
                                                            name: {
                                                                offsetY: 5,
                                                                show: true,
                                                                fontSize: "14px"
                                                            },
                                                            value: {
                                                                fontSize: "0px",
                                                                show: true
                                                            }
                                                        }
                                                    }
                                                },

                                                stroke: {
                                                    lineCap: "round",
                                                },
                                                labels: ['V3'],

                                            }
                                        }

                                        series={[Number(scheider_1?.voltage_v3).toFixed(2) * 100 / 400]}
                                        type="radialBar"
                                        width="60px"
                                        height="60px"
                                    // width="500"
                                    />
                                </span>
                                <span class='text-center py-1' style={{ width: '30%' }}>
                                    <span className='fw-bold' style={{ fontSize: '14px' }}>{Number(scheider_1?.voltage_v3).toFixed(2)} V</span>
                                </span>
                            </div>
                        </div>
                        <div class=' mb-1' >
                            <Table responsive="md">

                                <tbody>
                                    <tr>
                                        <td><span className='fw-bold' > Courant</span> (A)</td>
                                        <td><span className='fw-bold' >I1 </span>: {Number(scheider_1['current_I1']).toFixed(2)}</td>
                                        <td><span className='fw-bold' >I2 </span>: {Number(scheider_1['current_I2']).toFixed(2)} </td>
                                        <td><span className='fw-bold' >I3 </span>: {Number(scheider_1['current_I3']).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className='fw-bold' > Puissance active </span>(Kw)</td>
                                        <td><span className='fw-bold' >P1 </span>: {Number(scheider_1['active_power_L1']).toFixed(2)}</td>
                                        <td><span className='fw-bold' >P2 </span>: {Number(scheider_1['active_power_L2']).toFixed(2)} </td>
                                        <td><span className='fw-bold' >P3 </span>: {Number(scheider_1['active_power_L3']).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className='fw-bold' > Puissance reactive </span> (Kvar)</td>
                                        <td><span className='fw-bold' >Q1 </span>: {Number(scheider_1['reactive_power_L1']).toFixed(2)}</td>
                                        <td><span className='fw-bold' >Q2 </span>: {Number(scheider_1['reactive_power_L2']).toFixed(2)} </td>
                                        <td><span className='fw-bold' >Q3 </span>: {Number(scheider_1['reactive_power_L3']).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className='fw-bold' > Puissance apparente </span> (kva)</td>
                                        <td><span className='fw-bold' >S1 </span>: {Number(scheider_1['apparent_power_L1']).toFixed(2)}</td>
                                        <td><span className='fw-bold' >S2 </span>: {Number(scheider_1['apparent_power_L2']).toFixed(2)} </td>
                                        <td><span className='fw-bold' >S3 </span>: {Number(scheider_1['apparent_power_L3']).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className='fw-bold' > Pic de puissance journalière</span> (Kw)</td>
                                        <td><span className='fw-bold' >Min</span> : {Number(PminMaxMoyJour?.minimun).toFixed(2)}</td>
                                        <td><span className='fw-bold' >Max</span> : {Number(PminMaxMoyJour?.maximun).toFixed(2)} </td>
                                        <td><span className='fw-bold' >Moy</span> : {Number(PminMaxMoyJour?.moyenne).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className='fw-bold' > Energie journalière</span> (kwh)</td>
                                        <td><span className='fw-bold' >Min</span> : {Number(EminMaxMoyJour?.minimun/1000).toFixed(1)}</td>
                                        <td><span className='fw-bold' >Max</span> : {Number(EminMaxMoyJour?.maximun/1000).toFixed(1)} </td>
                                        <td><span className='fw-bold' >Moy</span> : {Number(EminMaxMoyJour?.moyenne/1000).toFixed(1)}</td> </tr>
                                    <tr>
                                        <td><span className='fw-bold' > Energie mensuel</span> (kwh)</td>
                                        <td><span className='fw-bold' >Min</span> : {Number(EminMaxMoyMois?.minimun/1000).toFixed(1)}</td>
                                        <td><span className='fw-bold' >Max</span> : {Number(EminMaxMoyMois?.maximun/1000).toFixed(1)} </td>
                                        <td><span className='fw-bold' >Moy</span> : {Number(EminMaxMoyMois?.moyenne/1000).toFixed(1)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div class="m-2 col-lg col-md-lg  p-0 d-flex justify-content-center" style={{ minWidth: '600px' }}>

                    <div class="card ms-2 col-lg col-md-lg  p-0 shadow-sm p-3  bg-white rounded">
                        <div class=" ">
                            <span> <AiFillPieChart class='fs-3 me-2' /></span>
                            <span className='fw-bold fs-6'>Repartition energetique Jour/Nuit</span>
                        </div>
                        <div class="card-body " >

                            <Chart
                                options={
                                    {
                                        chart: {
                                            width: 380,
                                            type: 'pie',
                                        },
                                        colors: ['#fc0362', '#07922a', '#0398fc', '#00FFAB', '#F7D716', '#97AF16'],
                                        labels: ['Energie_jour', 'Energie_nuit'],
                                        legend: {
                                            position: 'bottom'
                                        },
                                        responsive: [{
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: 200
                                                },
                                                legend: {
                                                    position: 'bottom'
                                                }
                                            }
                                        }]
                                    }
                                }

                                series={[Number(EnergiesJour), Number(EnergiesNuit)]}
                                type="pie"
                                width="110%"
                                height="110%"
                            //width="300px"
                            />
                            <Box sx={{ width: '100%' }} style={{ height: '15px' }} >
                                <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example" >
                                    <Tab value="7" label="1 sem" wrapped />
                                    <Tab value="30" label="1 mois" />
                                    <Tab value="90" label="3 mois" />
                                    <Tab value="366" label="1 an" />
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                    <div class="card ms-2 col-lg col-md-lg  p-0 shadow-sm p-3  bg-white rounded">
                        <div class=" ">
                            <span> <AiFillPieChart class='fs-3 me-2' /></span>
                            <span className='fw-bold fs-6'>Facteur de puissance</span>
                        </div>
                        <div class="card-body " style={{ height: '250px' }}>
                            <Chart
                                options={
                                    {
                                        chart: {
                                            type: 'polarArea',
                                        },
                                        legend: {
                                            position: 'bottom'
                                        },
                                        labels: ['Pf1', 'Pf2', 'Pf3'],
                                        stroke: {
                                            colors: ['#fff']
                                        },
                                        fill: {
                                            opacity: 0.9
                                        },
                                        responsive: [{
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: 200
                                                },
                                                legend: {
                                                    position: 'bottom'
                                                }
                                            }
                                        }]
                                    }
                                }

                                series={[Number(scheider_1?.power_factor_L1 * 100).toFixed(2), Number(scheider_1?.power_factor_L2 * 100).toFixed(2), Number(scheider_1?.power_factor_L3 * 100).toFixed(2)]}
                                type="polarArea"
                                width="110%"
                                height="110%"
                            //width="300px"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="card m-2 col-lg col-md-lg  p-0 d-flex justify-content-center text-center" style={{ minWidth: '600px' }}>

                    <div class="card-header fw-bold fs-6 mb-2 text-white" style={{ background: "#3489eb" }}>Puissance active (kw)</div>
                    <Box sx={{ width: '100%' }} className='ms-2 mb-4 mt-2' >
                        <div className='container d-flex justify-content-between ' >
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStartDateActivePower(date.target?.value)} ></input>
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStopDateActivePower(date.target?.value)} ></input>
                        </div>
                    </Box>
                    <Chart class='  ms-3 mb-2 py-1'
                        options={{
                            chart: {
                                type: 'area',
                                //stacked: true,

                            },
                            colors: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2
                            },

                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center'
                            },
                            xaxis: {
                                categories: date,
                                type: 'datetime',
                                labels: {
                                    show: true
                                }


                            },
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        return parseFloat(val).toFixed(2)
                                    }
                                }

                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return val + " Kw"
                                    }
                                },
                                x: {
                                    format: "dd.MM.yyyy HH:mm"
                                }
                            },

                        }}
                        series={[
                            {
                                name: 'Active power L1+',
                                data: activePower1
                            },
                            {
                                name: 'Active power L2+',
                                data: activePower2
                            },
                            {
                                name: 'Active power L3+',
                                data: activePower3
                            },
                        ]}

                        type='area'
                        width="95%"
                        height="380px"
                    // width="500"
                    />
                    <div class="card-header fw-bold fs-6 mt-3 text-white" style={{ background: "#3489eb" }}>Puissance apparente (kva)</div>
                    <Box sx={{ width: '100%' }} className='ms-2 mb-4 mt-2' >
                        <div className='container d-flex justify-content-between ' >
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStartDateApparentePower(date.target?.value)} ></input>
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStopDateApparentePower(date.target?.value)} ></input>
                        </div>
                    </Box>
                    <Chart class='  ms-3  py-1'
                        options={{
                            chart: {
                                type: 'area',
                                //stacked: true,

                            },
                            colors: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2
                            },

                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center'
                            },
                            xaxis: {
                                categories: dateApparentePower,
                                type: 'datetime',
                                labels: {
                                    show: true
                                }


                            },
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        return parseFloat(val).toFixed(2)
                                    }
                                }

                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return val + "Kva"
                                    }
                                },
                                x: {
                                    format: "dd.MM.yyyy HH:mm"
                                }
                            },

                        }}


                        series={[
                            {
                                name: 'Apparente power L1+',
                                data: apparentePower1
                            },
                            {
                                name: 'Apparente power L2+',
                                data: apparentePower2
                            },
                            {
                                name: 'Apparente power L3+',
                                data: apparentePower3
                            }
                        ]}

                        type='area'
                        width="95%"
                        height="380px"
                    // width="500"
                    />

                </div>
                <div class="card m-2 col-lg col-md-lg  p-0 d-flex justify-content-center text-center" style={{ minWidth: '600px' }}>

                    <div class="card-header fw-bold fs-6 mb-2 text-white" style={{ background: "#3489eb" }}>Puissance reactive (kvar)</div>
                    <Box sx={{ width: '100%' }} className='ms-2 mb-4 mt-2' >
                        <div className='container d-flex justify-content-between ' >
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStartDateReactivePower(date.target?.value)} ></input>
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStopDateReactivePower(date.target?.value)} ></input>
                        </div>
                    </Box>
                    <Chart class='  ms-3 mb-2 py-1'
                        options={{
                            chart: {
                                type: 'area',
                                //stacked: true,

                            },
                            colors: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2
                            },

                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center'
                            },
                            xaxis: {
                                categories: dateReactivePower,
                                type: 'datetime',
                                labels: {
                                    show: true
                                }


                            },
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        return parseFloat(val).toFixed(2)
                                    }
                                }

                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return val + "Kvar"
                                    }
                                },
                                x: {
                                    format: "dd.MM.yyyy HH:mm"
                                }
                            },

                        }}


                        series={[
                            {
                                name: 'Reactive power L1+',
                                data: reactivePower1
                            },
                            {
                                name: 'Reactive power L2+',
                                data: reactivePower2
                            },
                            {
                                name: 'Reactive power L3+',
                                data: reactivePower3
                            }
                        ]}

                        type='area'
                        width="95%"
                        height="380px"
                    // width="500"
                    />

                    <div class="card-header fw-bold fs-6 mt-3 text-white" style={{ background: "#3489eb" }}>Courant (A)</div>
                    <Box sx={{ width: '100%' }} className='ms-2 mb-4 mt-2' >
                        <div className='container d-flex justify-content-between ' >
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStartDate(date.target?.value)} ></input>
                            <input className='form-control no-border mx-1' type="date" onChange={(date) => setStopDate(date.target?.value)} ></input>
                        </div>
                    </Box>
                    <Chart class='  ms-3 mb-2 py-1'
                        options={{
                            chart: {
                                type: 'area',
                                //stacked: true,

                            },
                            colors: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2
                            },

                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center'
                            },
                            xaxis: {
                                categories: dateCourant,
                                type: 'datetime',
                                labels: {
                                    show: true
                                }


                            },
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        return parseFloat(val).toFixed(2)
                                    }
                                }

                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return val + "A"
                                    }
                                },
                                x: {
                                    format: "dd.MM.yyyy HH:mm"
                                }
                            },

                        }}


                        series={[
                            {
                                name: 'Current_L1',
                                data: courant_1
                            },
                            {
                                name: 'Current_L2',
                                data: courant_2
                            },
                            {
                                name: 'Current_L3',
                                data: courant_3
                            },
                        ]}

                        type='area'
                        width="95%"
                        height="380px"
                    // width="500"
                    />
                </div>
            </div>
            <Box className='mt-4' sx={{ flexGrow: 1 }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={7} >
                        <Item>
                            <Box class="card-header fw-bold fs-6 m-2 text-white text-center" style={{ background: "#3489eb" }} >Energie journalière (kwh)</Box>

                            <Box sx={{ width: '100%' }} className='ms-2' >
                                <Tabs value={Energie} onChange={handleChangeEnergie} aria-label="wrapped label tabs example" >
                                    <Tab value="90" label="3 mois" wrapped />
                                    <Tab value="180" label="6 mois" />
                                    <Tab value="366" label="1 an" />
                                </Tabs>
                            </Box>
                            <Chart class='ms-3 py-1'
                                options={
                                    {
                                        chart: {
                                            type: 'bar',
                                            height: 350
                                        },
                                        colors: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
                                        plotOptions: {
                                            bar: {
                                                horizontal: false,
                                                columnWidth: '30%',
                                                endingShape: 'rounded'
                                            },
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                        },
                                        xaxis: {
                                            type: 'datetime',
                                            categories: dateEnergy
                                        },
                                        yaxis: {
                                            title: {
                                                text: 'Energie kwh'
                                            },
                                            labels: {
                                                formatter: function (val) {
                                                    return parseFloat(val).toFixed(2)
                                                }
                                            }
                                        },

                                        fill: {
                                            opacity: 1
                                        },
                                        tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                    return val + " kwh"
                                                }
                                            },
                                            x: {
                                                format: "dd.MM "
                                            }

                                        }

                                    }
                                }
                                series={[{
                                    name: 'Energie',
                                    data: energyP
                                },
                                ]}
                                type="bar"
                                width="95%"
                                height="310px"
                            // width="500"
                            />

                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                        <Item style={{ height: "" }}>
                            <Box sx={{ width: '100%' }} className='mb-4 d-flex justify-content-around' >
                                <span className='fw-bold fs-5'>Tranche de consommation (Mwh)</span>
                            </Box>
                            <Box sx={{ width: '100%' }} className='ms-2 mb-4 mt-2' >
                                <div className='container d-flex justify-content-between ' >
                                    <input className='form-control no-border mx-1' type="date" onChange={(date) => setStartDatetrache(date.target?.value)} ></input>
                                    <input className='form-control no-border mx-1' type="date" onChange={(date) => setStopDatetrache(date.target?.value)} ></input>
                                </div>
                            </Box>
                            <Chart class=''
                                options={{
                                    chart: {
                                        type: 'bar',
                                        height: 350
                                    },

                                    plotOptions: {
                                        bar: {
                                            horizontal: true,
                                        }
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function (val) {
                                            return val + " Mwh"
                                            //return `tranche ${val}`
                                        }
                                    },
                                    xaxis: {
                                        categories: ["H.Pointe", "H.Pliene", "H.Creuse"],
                                        labels: {
                                            format: 'MMM/yyy',
                                        }

                                    },


                                    yaxis: {
                                        //reversed: true,

                                        axisTicks: {
                                            show: true
                                        },/*
                                        labels: {
                                            formatter: function (val) {
                                                let d = new Date(val);
                                                let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                                                let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
                                                //let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                                                return `${mo}-${ye}`
                                            }
                                        }*/
                                    }

                                }}
                                series={[{
                                    name: 'Heure de pointe',
                                    data: [(heurePointe/1000000).toFixed(2)]
                                }, {
                                    name: 'Heure pleine',
                                    data: [(heurePleine/1000000).toFixed(2)]
                                }, {
                                    name: 'Heure creuse',
                                    data: [(heureCreuse/1000000).toFixed(2)]
                                }
                                ]}
                                type="bar"
                                width="95%"
                                height="300px"
                            // width="500"
                            />

                        </Item>
                    </Grid>

                </Grid>
            </Box>
        </div>
    );
}

export default SCHNEIDER_7;