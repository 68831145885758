import React, { useEffect, useState } from "react";
import axios from 'axios';

import { resetpass } from "../Config"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { homePageInfo } from "../Contexts/Fonction";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, salle, as, ligne) {
    return { name, salle, as, ligne };
}





const Parametre = () => {

    const [Schneider_1, setSchneider_1] = useState([]);
    const [Schneider_2, setSchneider_2] = useState([]);
    const [Schneider_3, setSchneider_3] = useState([]);
    const [Schneider_4, setSchneider_4] = useState([]);
    const [Schneider_5, setSchneider_5] = useState([]);
    const [Schneider_6, setSchneider_6] = useState([]);
    const [Schneider_7, setSchneider_7] = useState([]);
    const [Schneider_8, setSchneider_8] = useState([]);
    const [Schneider_9, setSchneider_9] = useState([]);
    const [Schneider_10, setSchneider_10] = useState([]);
    const [Schneider_11, setSchneider_11] = useState([]);
    const [Schneider_12, setSchneider_12] = useState([]);
    const [Schneider_13, setSchneider_13] = useState([]);



    const [checked, setChecked] = React.useState(true);



    const [credentials, setCredentials] = useState({ email: "", password: "", newPass: "", confirmPass: "" })
    const [voirpass, setvoirpass] = useState(false)
    const [erreur, seterreur] = useState("")

    const handlchange = ({ currentTarget }) => {
        console.log(currentTarget)
        const { value, name } = currentTarget
        setCredentials({ ...credentials, [name]: value })
    }

    const handleChangeCheckBox = (event) => {
        setChecked(event.target.checked);

    };

    const handlsubmite = async (event) => {
        event.preventDefault();
        try {
            await axios.post(resetpass, credentials)
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    seterreur(data)
                    //window.localStorage.setItem('user', data.email)
                    //window.localStorage.setItem('token', data.token)
                    //axios.defaults.headers["x-access-token"] = data.token
                    //axios.defaults.headers["Authorization"] = data.token
                    //console.log(jwtDecode(data.token))
                    //setToken(data.token)-----
                    //console.log(isAuthenticated())
                    //isAuthenticated(setToken)

                })
        } catch (error) {

            console.log(error)
        }
    }



    useEffect(() => {
        seterreur("")
        const interval = setInterval(() => {
            seterreur("")
        }, 8000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        [setSchneider_1,setSchneider_2,setSchneider_3,setSchneider_4,setSchneider_5,setSchneider_6,setSchneider_7,
          setSchneider_8,setSchneider_9,setSchneider_10,setSchneider_11, setSchneider_12,setSchneider_13,
        ].map((element, index) => {
          homePageInfo(element, index + 1);
        });
        const interval = setInterval(() => {
            [setSchneider_1,setSchneider_2,setSchneider_3,setSchneider_4,setSchneider_5,setSchneider_6,setSchneider_7,
                setSchneider_8,setSchneider_9,setSchneider_10,setSchneider_11, setSchneider_12,setSchneider_13,
              ].map((element, index) => {
            homePageInfo(element, index + 1);
          });
        }, 300000);
        return () => {
          clearInterval(interval);
        };
      }, []);

      const rows = [
        createData('Schneider_1', 'TGBT_8 cuisson / presse SCK_8', <span>{(Schneider_1.voltage_v1 == null || isNaN(Schneider_1.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_2', 'TGBT_11', <span>{(Schneider_2.voltage_v1 == null || isNaN(Schneider_2.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_3', 'TGBT_12', <span>{(Schneider_3.voltage_v1 == null || isNaN(Schneider_3.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_4', 'TGBT_2 : Atomiseur P1_P2', <span>{(Schneider_4.voltage_v1 == null || isNaN(Schneider_4.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_5', 'TGBT_2 : Atomiseur P1_P2', <span>{(Schneider_5.voltage_v1 == null || isNaN(Schneider_5.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_6', 'TGBT_4 : Rechauffeur  gaz + compresseur + eclairage + station de pompage', <span>{(Schneider_6.voltage_v1 == null || isNaN(Schneider_6.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_7', 'TGBT_8 : Atomiseur_8 + compresseur_9', <span>{(Schneider_7.voltage_v1 == null || isNaN(Schneider_7.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_8', 'TGBT_1 : Broyeur continu', <span>{(Schneider_8.voltage_v1 == null || isNaN(Schneider_8.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_9', 'SCK_9 + PDM_9 + LEM + cuisson', <span>{(Schneider_9.voltage_v1 == null || isNaN(Schneider_9.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_10', 'SCK_9 + PDM_9 + LEM + cuisson', <span>{(Schneider_10.voltage_v1 == null || isNaN(Schneider_10.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_11', 'TGBT_4 : Rechauffeur  gaz + compresseur + eclairage + station de pompage', <span>{(Schneider_11.voltage_v1 == null || isNaN(Schneider_11.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_12', 'TGBT_1 : Broyeur continu', <span>{(Schneider_12.voltage_v1 == null || isNaN(Schneider_12.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
        createData('Schneider_13', 'TGBT_4 : Rechauffeur  gaz + compresseur + eclairage + station de pompage', <span>{(Schneider_13.voltage_v1 == null || isNaN(Schneider_13.voltage_v1) ) ?<Button variant="contained" color="error">Deconnecte</Button> :<Button variant="contained" color="success">Connecte</Button> }</span>, '--'),
    
      
    
    ];


    return (

        <div className="container" >

            <div className="mt-5">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>N° compteur</StyledTableCell>
                                <StyledTableCell align="right">salle</StyledTableCell>
                                <StyledTableCell align="right">Etat connectivité</StyledTableCell>
                                <StyledTableCell align="right">N° ligne</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.salle}</StyledTableCell>
                                    <StyledTableCell align="right">{row.as}</StyledTableCell>
                                    <StyledTableCell align="right">{row.ligne}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>


            <div className="mt-5 text-center fs-5">
                Modifier le mot de passe
            </div>

            <div className="container col-sm col-md-sm card  mt-5 ">
                <Checkbox className="position-absolute top-0 start-0 translate "
                    checked={checked}
                    onChange={handleChangeCheckBox}
                    inputProps={{ 'aria-label': 'controlled' }}
                />

                <div class='col  mt-2 mb-2 text-center'  >
                    <form onSubmit={handlsubmite} >
                        <div className='mb-4 mt-4'>
                            <TextField name="email" id="email" label="User" variant="standard" fullWidth onChange={handlchange} disabled={checked} />
                        </div>
                        <div className='mt-2 '>
                            <span className='d-flex align-items-center  justify-content-center'>
                                <TextField name="password"
                                    id="exampleInputPassword1"
                                    label="Password"
                                    variant={"standard"}
                                    type={voirpass ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    fullWidth onChange={handlchange}
                                    disabled={checked}
                                />
                                <span onClick={() => { setvoirpass(!voirpass) }} >{voirpass ? <VisibilityIcon /> : <VisibilityOffIcon />} </span>
                            </span>

                        </div>
                        <div className='mt-2'>
                            <span className='d-flex align-items-center  justify-content-center'>
                                <TextField name="newPass"
                                    id="exampleInputPassword11"
                                    label="New password"
                                    variant={"standard"}
                                    type={voirpass ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    fullWidth onChange={handlchange}
                                    disabled={checked}
                                />

                            </span>

                        </div>

                        <div className='mt-2'>
                            <span className='d-flex align-items-center  justify-content-center'>
                                <TextField name="confirmPass"
                                    id="exampleInputPassword111"
                                    label="Confirm password"
                                    variant={"standard"}
                                    type={voirpass ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    fullWidth onChange={handlchange}
                                    disabled={checked}
                                />

                            </span>

                        </div>
                        <div className='container text-center mt-4 text-danger fs-6'>{
                            erreur === "votre mot de passe a été modifié avec succès" ?
                                <Alert severity="success">{erreur}</Alert> :
                                erreur === "" ? "" :
                                    <Alert severity="error">{erreur}</Alert>}
                        </div>
                        <Button variant="contained" color='info' endIcon={<EditIcon />} type="submit" fullWidth className='mt-3' disabled={checked} >
                            Modifier
                        </Button>

                    </form>

                </div>
            </div>




        </div>

    );
}

export default Parametre;