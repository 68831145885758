import SideBar from "../Component/SideBar";

const Home = () => {
    return (
        <div>
            <SideBar/>
        </div>
    );
}

export default Home;


