import Header from "./Header";
import axios from 'axios';
import {
    lastElement,
    filterActivePowerByHours,
    filterReactivePowerByHours,
    filterApparentePowerByHours,
    filterRepartitionEnergetiqueJournaliere,
    filterCurrentByHours,
    filterEnergyJour,
    filterEnergyNuit,
    filterRepartitionPowerJourMinMaxMoyen,
    filterRepartitionEnergetiqueJourMinMaxMoyen,
    filterRepartitionEnergetiqueMoisMinMaxMoyen,
    filterRepartitionEnergetiqueMois,
    filterRepartitionActiveEnergyMois,
    filterRepartitionReactiveEnergyMois,
    filterEnergyHeurePointe,
    filterEnergyHeurePleine,
    filterEnergyHeureCreuse,
    filterEnergyHeureSuperPointe,

    filterRepartitionEnergieApparenteTotalMois,
    filterRepartitionEnergieActiveTotalMois,
    filterRepartitionEnergieReactiveTotalMois,
    filterRepartitionEnergetiqueJournaliereTotal,
    filterEnergyHeurePointeTotal,
    filterEnergyHeurePleineTotal,
    filterEnergyHeureCreuseTotal,

} from "../Config";



export const homePageInfo = (all, id) => {
    axios.get(`${lastElement}${id}`, Header()).then((response) => {
        //console.log(response?.data)
        all(response?.data)
    });
}


export const activePower = (x, L1, L2, L3, idCollection, idHour, IdendHour) => {
    axios.get(`${filterActivePowerByHours}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log("active", response.data[0]?.L1_Active_power_positive)
        x(response.data[0]?.id)
        L1(response.data[0]?.L1_Active_power_positive)
        L2(response.data[0]?.L2_Active_power_positive)
        L3(response.data[0]?.L3_Active_power_positive)
        /*
        response.data.forEach((item) => {
            //console.log(item.id, item.courant_1, item.courant_2, item.courant_3)
            x(item.id)
            L1(item.L1_Active_power_positive)
            L2(item.L2_Active_power_positive)
            L3(item.L3_Active_power_positive)

        })
        */

    });
}

export const reactivePower = (x, L1, L2, L3, idCollection, idHour, IdendHour) => {
    axios.get(`${filterReactivePowerByHours}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log("reactive",response.data) 
        x(response.data[0]?.id)
        L1(response.data[0]?.L1_Reactive_power_positive)
        L2(response.data[0]?.L2_Reactive_power_positive)
        L3(response.data[0]?.L3_Reactive_power_positive)
        /*
        response.data.forEach((item) => {
            x(item.id)
            L1(item.L1_Reactive_power_positive)
            L2(item.L2_Reactive_power_positive)
            L3(item.L3_Reactive_power_positive)

        })
        */
    });
}

export const apparentePower = (x, L1, L2, L3, idCollection, idHour, IdendHour) => {
    axios.get(`${filterApparentePowerByHours}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log("apparente",response.data) 

        x(response.data[0]?.id)
        L1(response.data[0]?.L1_Apparente_power_positive)
        L2(response.data[0]?.L2_Apparente_power_positive)
        L3(response.data[0]?.L3_Apparente_power_positive)
        /*
        response.data.forEach((item) => {
            x(item.id)
            L1(item.L1_Apparente_power_positive)
            L2(item.L2_Apparente_power_positive)
            L3(item.L3_Apparente_power_positive)

        })
        */

    });
}

//energie active journaliere
export const apparenteEnergy = (x_date, energie_apparente_journaliere, idCollection, idDays) => {
    //x_date, energie_L1, energie_L2, energie_L3, id
    axios.get(`${filterRepartitionEnergetiqueJournaliere}${idCollection}/${idDays}`, Header()).then((response) => {
        //console.log(response.data)
        const date_energie = [];
        const energie_1 = [];
        response.data?.forEach((item) => {
            date_energie.push(item?._id)
            energie_1.push((item?.energy/1000).toFixed(2)) //en kwh
        })
        x_date(date_energie)
        energie_apparente_journaliere(energie_1)



    });
}

//energie apparente
export const apparenteEnergyMois = (x_date, energie_apparente, idCollection, idDays) => {
    axios.get(`${filterRepartitionEnergetiqueMois}${idCollection}/${idDays}`, Header()).then((response) => {
        //console.log(response.data)
        const date_energie = [];
        const energie_1 = [];
        response.data?.forEach((item) => {
            date_energie.push(item?._id)
            energie_1.push(item?.energy)
        })
        x_date(date_energie)
        energie_apparente(energie_1)
        

    });
}


export const activeEnergyMois = (x_date, energie_active, idCollection, idDays) => {
    axios.get(`${filterRepartitionActiveEnergyMois}${idCollection}/${idDays}`, Header()).then((response) => {
        const date_energie = [];
        const energie_1 = [];
        response.data?.forEach((item) => {
            date_energie.push(item?._id)
            energie_1.push(item?.energy)
        })
        x_date(date_energie)
        energie_active(energie_1)
        //console.log(energie_1)
    });
}

export const reactiveEnergyMois = (x_date, energie_reactive, idCollection, idDays) => {
    axios.get(`${filterRepartitionReactiveEnergyMois}${idCollection}/${idDays}`, Header()).then((response) => {
        const date_energie = [];
        const energie_1 = [];
        response.data?.forEach((item) => {
            date_energie.push(item?._id)
            energie_1.push(item?.energy)
        })
        x_date(date_energie)
        energie_reactive(energie_1)
        //console.log(energie_1)
    });
}

/*
export const activeEnergyMois = (x_date, tranche, idCollection, idDays) => {
    //x_date, energie_L1, energie_L2, energie_L3, id
    axios.get(`${filterRepartitionActiveEnergyMois}${idCollection}/${idDays}`, Header()).then((response) => {
        //console.log(response.data)
        const date_energie = [];
        const energie_1 = [];

        response.data?.forEach((item) => {
            //console.log(item.id, item.energie_1, item.energie_2, item.energie_3)
            //x_date.push(item._id)
            var energie = (Number(item.energy) * 0.001);
            //console.log("energie-----------",energie)
            if (energie <= 100) {

                date_energie.push(item._id)
                energie_1.push(1)
            }
            if (energie > 100 && energie <= 500) {

                date_energie.push(item._id)
                energie_1.push(2)
            }
            if (energie > 500) {
                date_energie.push(item._id)
                energie_1.push(3)
            }
            //date_energie.push(item._id)
            //energie_1.push(item.energy)
        })
        x_date(date_energie)
        tranche(energie_1)
        //console.log(date_energie, energie_1, energie_2, energie_3)
        //console.log(c)
    });
}
*/
export const emissionco2 = (x_date, co2, idCollection, idDays) => {
    //x_date, energie_L1, energie_L2, energie_L3, id
    axios.get(`${filterRepartitionEnergetiqueMois}${idCollection}/${idDays}`, Header()).then((response) => {
        //console.log(response.data)

        const date_energie = [];
        const energie_1 = [];


        response.data.forEach((item) => {
            //console.log(item.id, item.energie_1, item.energie_2, item.energie_3)
            //x_date.push(item._id)
            date_energie.push(item._id)
            energie_1.push(Number(item.energy) * 0.756)


        })
        x_date(date_energie)
        co2(energie_1)

        //console.log(date_energie, energie_1, energie_2, energie_3)
        //console.log(c)

    });
}
export const courant = (x, L1, L2, L3, idCollection, idHour, IdendHour) => {
    axios.get(`${filterCurrentByHours}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log("courant",response.data)
        x(response.data[0]?.id)
        L1(response.data[0]?.courant_1)
        L2(response.data[0]?.courant_2)
        L3(response.data[0]?.courant_3)

        /*
        response.data.forEach((item) => {
            //console.log(item.id, item.courant_1, item.courant_2, item.courant_3)
            x(item.id)
            L1(item.courant_1)
            L2(item.courant_2)
            L3(item.courant_3)

        })
        */

    });
}

export const puissanceMinMaxMoyJour = (PminPaxPoy, idCollection, idDay) => {
    axios.get(`${filterRepartitionPowerJourMinMaxMoyen}${idCollection}/${idDay}`, Header()).then((response) => {
        //console.log(response.data)
        PminPaxPoy(response?.data[0])
        //console.log("gggggggggggggggggggggggg",response.data[1])
    });
}

export const energieMinMaxMoyJour = (PminPaxPoy, idCollection, idDay) => {
    axios.get(`${filterRepartitionEnergetiqueJourMinMaxMoyen}${idCollection}/${idDay}`, Header()).then((response) => {
        //console.log(response.data)
        PminPaxPoy(response?.data[0])
        //console.log("gggggggggggggggggggggggg",response.data)
    });
}

export const energieMinMaxMoyMois = (PminPaxPoy, idCollection, idDay) => {
    axios.get(`${filterRepartitionEnergetiqueMoisMinMaxMoyen}${idCollection}/${idDay}`, Header()).then((response) => {
        //console.log(response.data)
        PminPaxPoy(response?.data[0])
        //console.log("gggggggggggggggggggggggg",response.data)
    });
}



export const energie_jour = (E_jour, idCollection, idDay) => {
    axios.get(`${filterEnergyJour}${idCollection}/${idDay}`, Header()).then((response) => {
        //console.log("----",response.data)
        const E1 = [];
        response.data?.forEach((item) => {
            E1.push(item?.EnergieJour).toFixed(2)
        })
        //console.log(E1, E2, E3)
        E_jour(E1)
        //console.log(E1)


    });
}


export const energie_nuit = (E_nuit, idCollection, idDay) => {
    axios.get(`${filterEnergyNuit}${idCollection}/${idDay}`, Header()).then((response) => {
        //console.log(response.data)
        const E1 = [];
        response.data?.forEach((item) => {
            E1.push(item?.EnergieNuit).toFixed(2)
        })
        //console.log(E1, E2, E3)
        E_nuit(E1)
        //console.log(E1)


    });
}


//nouveau--------------
export const energieApparenteMoisTotal13coll = (all, idDay) => {
    axios.get(`${filterRepartitionEnergieApparenteTotalMois}${idDay}`, Header()).then((response) => {
        //console.log("energiieeeeeeApparenre",response?.data)
        all(response?.data)
    });
}
export const energieActiveMoisTotal13coll = (all, idDay) => {
    axios.get(`${filterRepartitionEnergieActiveTotalMois}${idDay}`, Header()).then((response) => {
        //console.log("energiieeeeeeActive",response?.data)
        all(response?.data)
    });
}

export const energieReactiveMoisTotal13coll = (all, idDay) => {
    axios.get(`${filterRepartitionEnergieReactiveTotalMois}${idDay}`, Header()).then((response) => {
        //console.log("energiieeeeeeeRecative",response?.data)
        all(response?.data)
    });
}


export const energieheurePointeTotal13coll = (All, idHour, IdendHour) => {
    axios.get(`${filterEnergyHeurePointeTotal}${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log("HEUREde pointe",response?.data)
        All(response?.data)
    });
}
export const energieheurePleineTotal13coll = (All, idHour, IdendHour) => {
    axios.get(`${filterEnergyHeurePleineTotal}${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log("HEUREde pleine",response?.data)
        All(response?.data)
    });
}
export const energieheureCreuseTotal13coll = (All, idHour, IdendHour) => {
    axios.get(`${filterEnergyHeureCreuseTotal}${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log("HEUREde creuse",response?.data)
        All(response?.data)
    });
}

export const energiejournaliereTotal13coll = (All,idDay)=> {
    axios.get(`${filterRepartitionEnergetiqueJournaliereTotal}${idDay}`, Header()).then((response) => {
        //console.log("energie journaliere",response?.data)
        All(response?.data)
    });
}
//fin nouveau-----------



export const energie_heurePointe = (E_heurePointe, idCollection, idHour, IdendHour) => {
    axios.get(`${filterEnergyHeurePointe}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log(response?.data[0].EnergieheurePointe.toFixed(2))
        E_heurePointe(response?.data[0].EnergieheurePointe.toFixed(2))

    });
}
export const energie_heurePleine = (E_heurePleine, idCollection, idHour, IdendHour) => {
    axios.get(`${filterEnergyHeurePleine}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log(response)
        //console.log("heure pleine",response.data[0].EnergieheurePleine.toFixed(2))
        E_heurePleine(response?.data[0].EnergieheurePleine.toFixed(2))
    });
}

export const energie_heureCreuse = (E_heureCreuse, idCollection, idHour, IdendHour) => {
    axios.get(`${filterEnergyHeureCreuse}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log(response.data)
        //console.log("heure pleine",response?.data[0].EnergieheureCreuse.toFixed(2))
        E_heureCreuse(response?.data[0].EnergieheureCreuse.toFixed(2))
    });
}

export const energie_heureSuperPointe = (E_heureSuperPointe, idCollection, idHour, IdendHour) => {
    axios.get(`${filterEnergyHeureSuperPointe}${idCollection}/${idHour}/${IdendHour}`, Header()).then((response) => {
        //console.log(response.data)
        //console.log("heure pleine",response?.data[0].EnergieheureCreuse.toFixed(2))
        E_heureSuperPointe(response?.data[0].EnergieheureSuperPointe.toFixed(2))
    });
}











/*
export const energieHeure_PointePleineCreuse = (EhPointe,EhPleine,EhCreuse,EhSuperPointe,idHour, IdendHour)=>{
    let pleine =0
    let pointe = 0
    let creuse =0
    let superpointe=0

    let urlPleine=[
        filterEnergyHeurePleine+"1/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"2/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"3/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"4/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"5/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"6/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"7/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"8/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"9/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"10/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"11/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"12/"+idHour+"/"+IdendHour,
        filterEnergyHeurePleine+"13/"+idHour+"/"+IdendHour,

    ]
    let urlPointe=[
        filterEnergyHeurePointe+"1/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"2/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"3/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"4/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"5/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"6/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"7/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"8/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"9/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"10/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"11/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"12/"+idHour+"/"+IdendHour,
        filterEnergyHeurePointe+"13/"+idHour+"/"+IdendHour,

    ]
    let urlCreuse=[
        filterEnergyHeureCreuse+"1/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"2/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"3/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"4/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"5/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"6/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"7/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"8/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"9/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"10/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"11/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"12/"+idHour+"/"+IdendHour,
        filterEnergyHeureCreuse+"13/"+idHour+"/"+IdendHour,

    ]
    let urlSuperPointe=[
        filterEnergyHeureSuperPointe+"1/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"2/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"3/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"4/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"5/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"6/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"7/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"8/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"9/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"10/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"11/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"12/"+idHour+"/"+IdendHour,
        filterEnergyHeureSuperPointe+"13/"+idHour+"/"+IdendHour,

    ]
    const requestsPleine = urlPleine.map((urlPleine) => axios.get(urlPleine, Header()));
    const requestsPointe = urlPointe.map((urlPointe) => axios.get(urlPointe, Header()));
    const requestsCreuse = urlCreuse.map((urlCreuse) => axios.get(urlCreuse, Header()));
    const requestsSuperPointe = urlSuperPointe.map((urlSuperPointe) => axios.get(urlSuperPointe, Header()));

    axios.all(requestsPleine).then((response) => {
        //console.log("tous",response)
        response.forEach((item)=>{
            //console.log(item?.data[0].EnergieheurePleine)
            pleine+=Number(item?.data[0]?.EnergieheurePleine)
            
        })
        //console.log('energie total heure pleine',Number(pleine).toFixed(2))
        EhPleine(Number(pleine).toFixed(2))

    });
    axios.all(requestsPointe).then((response) => {
        //console.log("tous",response)
        response.forEach((item)=>{
            //console.log(item?.data[0].EnergieheurePointe)
            pointe+=Number(item?.data[0]?.EnergieheurePointe)
            
        })
        //console.log('energie total heure pointe',Number(pointe).toFixed(2))
        EhPointe(Number(pointe).toFixed(2))
    
    });
    axios.all(requestsCreuse).then((response) => {
        //console.log("tous",response)
        response.forEach((item)=>{
            //console.log(item?.data[0].EnergieheureCreuse)
            creuse+=Number(item?.data[0]?.EnergieheureCreuse)
            
        })
        //console.log('energie total heure creuse',Number(creuse).toFixed(2))
        EhCreuse(Number(creuse).toFixed(2))
    
    });
    axios.all(requestsSuperPointe).then((response) => {
        //console.log("tous",response)
        response.forEach((item)=>{
            //console.log(item?.data[0].EnergieheureCreuse)
            superpointe+=Number(item?.data[0]?.EnergieheureSuperPointe)
            
        })
        //console.log('energie total heure creuse',Number(creuse).toFixed(2))
        EhSuperPointe(Number(superpointe).toFixed(2))
    
    });
    
    //EhPointe(Number(pointe).toFixed(2))
    //EhPleine(Number(pleine).toFixed(2))
    //EhCreuse(Number(creuse).toFixed(2))

}




export const apparenteEnergyMoisTotale = (
    date__1, date__2, date__3, date__4, date__5, date__6, date__7, date__8, date__9, date__10, date__11, date__12, date__13,
    value_1, value_2, value_3, value_4, value_5, value_6, value_7, value_8, value_9, value_10, value_11, value_12, value_13,
    date_energie,energie_apparente
) => {
    const energie = [];
    const date = [];
    //let keys = ["name", "age", "gender"];
    //let values = ["John", 30, "male"];
    //let keyValuePairs = keys.map((key, index) => ({ [key]: values[index] }));
    //console.log(keyValuePairs);

    let compteur_1 = date__1?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_1[index] }), {});
    let compteur_2 = date__2?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_2[index] }), {});
    let compteur_3 = date__3?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_3[index] }), {});
    let compteur_4 = date__4?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_4[index] }), {});
    let compteur_5 = date__5?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_5[index] }), {});
    let compteur_6 = date__6?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_6[index] }), {});
    let compteur_7 = date__7?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_7[index] }), {});
    let compteur_8 = date__8?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_8[index] }), {});
    let compteur_9 = date__9?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_9[index] }), {});
    let compteur_10 = date__10?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_10[index] }), {});
    let compteur_11 = date__11?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_11[index] }), {});
    let compteur_12 = date__12?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_12[index] }), {});
    let compteur_13 = date__13?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_13[index] }), {});

    let tousLesCompteur = [compteur_1,compteur_2,compteur_3,compteur_4,compteur_5,compteur_6,compteur_7,compteur_8,compteur_9,compteur_10,compteur_11,compteur_12,compteur_13];
    //console.log(compteur_1,compteur_2,compteur_3,compteur_4,compteur_5,compteur_6,compteur_7,compteur_8,compteur_9,compteur_10,compteur_11,compteur_12,compteur_13);
    //console.log(tousLesCompteur)
    let result = Object.values(tousLesCompteur.reduce((acc, obj) => {
        acc[obj.date] = acc[obj.date] || {date: obj.date, value: 0};
        acc[obj.date].value += obj.value;
        return acc;
    }, {}));
    //console.log("resultat",result);

    result.forEach((item)=>{
        energie.push(item.value)
        date.push(item.date)
    })
    energie_apparente(energie)
    date_energie(date)
    //console.log("energie",energie,"date",date);
}

export const activeEnergyMoisTotale = (
    date__1, date__2, date__3, date__4, date__5, date__6, date__7, date__8, date__9, date__10, date__11, date__12, date__13,
    value_1, value_2, value_3, value_4, value_5, value_6, value_7, value_8, value_9, value_10, value_11, value_12, value_13,
    date_energie,energie_active,co2
) => {
    const energie = [];
    const date = [];
    const carbone = [];
    //let keys = ["name", "age", "gender"];
    //let values = ["John", 30, "male"];
    //let keyValuePairs = keys.map((key, index) => ({ [key]: values[index] }));
    //console.log(keyValuePairs);

    let compteur_1 = date__1?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_1[index] }), {});
    let compteur_2 = date__2?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_2[index] }), {});
    let compteur_3 = date__3?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_3[index] }), {});
    let compteur_4 = date__4?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_4[index] }), {});
    let compteur_5 = date__5?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_5[index] }), {});
    let compteur_6 = date__6?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_6[index] }), {});
    let compteur_7 = date__7?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_7[index] }), {});
    let compteur_8 = date__8?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_8[index] }), {});
    let compteur_9 = date__9?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_9[index] }), {});
    let compteur_10 = date__10?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_10[index] }), {});
    let compteur_11 = date__11?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_11[index] }), {});
    let compteur_12 = date__12?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_12[index] }), {});
    let compteur_13 = date__13?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_13[index] }), {});

    let tousLesCompteur = [compteur_1,compteur_2,compteur_3,compteur_4,compteur_5,compteur_6,compteur_7,compteur_8,compteur_9,compteur_10,compteur_11,compteur_12,compteur_13];
    //console.log(compteur_1,compteur_2,compteur_3,compteur_4,compteur_5,compteur_6,compteur_7,compteur_8,compteur_9,compteur_10,compteur_11,compteur_12,compteur_13);
    //console.log(tousLesCompteur)
    let result = Object.values(tousLesCompteur.reduce((acc, obj) => {
        acc[obj.date] = acc[obj.date] || {date: obj.date, value: 0};
        acc[obj.date].value += obj.value;
        return acc;
    }, {}));
    //console.log("resultat",result);

    result.forEach((item)=>{
        date.push(item.date)
        energie.push(item.value)
        carbone.push(Number(item.value)*0.756)
        
    })
    energie_active(energie)
    date_energie(date)
    co2(carbone)
    //console.log("energie",energie,"date",date);
}

export const reactiveEnergyMoisTotale = (
    date__1, date__2, date__3, date__4, date__5, date__6, date__7, date__8, date__9, date__10, date__11, date__12, date__13,
    value_1, value_2, value_3, value_4, value_5, value_6, value_7, value_8, value_9, value_10, value_11, value_12, value_13,
    date_energie,energie_reactive
) => {
    const energie = [];
    const date = [];
    //let keys = ["name", "age", "gender"];
    //let values = ["John", 30, "male"];
    //let keyValuePairs = keys.map((key, index) => ({ [key]: values[index] }));
    //console.log(keyValuePairs);

    let compteur_1 = date__1?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_1[index] }), {});
    let compteur_2 = date__2?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_2[index] }), {});
    let compteur_3 = date__3?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_3[index] }), {});
    let compteur_4 = date__4?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_4[index] }), {});
    let compteur_5 = date__5?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_5[index] }), {});
    let compteur_6 = date__6?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_6[index] }), {});
    let compteur_7 = date__7?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_7[index] }), {});
    let compteur_8 = date__8?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_8[index] }), {});
    let compteur_9 = date__9?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_9[index] }), {});
    let compteur_10 = date__10?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_10[index] }), {});
    let compteur_11 = date__11?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_11[index] }), {});
    let compteur_12 = date__12?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_12[index] }), {});
    let compteur_13 = date__13?.reduce((obj, key, index) => Object.assign(obj, { date:key.substring(0, 7),value: value_13[index] }), {});

    let tousLesCompteur = [compteur_1,compteur_2,compteur_3,compteur_4,compteur_5,compteur_6,compteur_7,compteur_8,compteur_9,compteur_10,compteur_11,compteur_12,compteur_13];
    //console.log(compteur_1,compteur_2,compteur_3,compteur_4,compteur_5,compteur_6,compteur_7,compteur_8,compteur_9,compteur_10,compteur_11,compteur_12,compteur_13);
    //console.log(tousLesCompteur)
    let result = Object.values(tousLesCompteur.reduce((acc, obj) => {
        acc[obj.date] = acc[obj.date] || {date: obj.date, value: 0};
        acc[obj.date].value += obj.value;
        return acc;
    }, {}));
    //console.log("resultat",result);

    result.forEach((item)=>{
        energie.push(item.value)
        date.push(item.date)
    })
    energie_reactive(energie)
    date_energie(date)
    //console.log("energie",energie,"date",date);
}

*/