/*
export const resetpass ="http://localhost:3000/resetpass"
export const user = "http://localhost:3000/profil"
export const URL_LOGIN = "http://localhost:3000/login"
export const lastElement = "http://localhost:3000/lastElement/"

export const allElement ="http://localhost:3000/allElement/"
export const filterCurrentByHours ="http://localhost:3000/filterCurrentByHours/"
export const filterActivePowerByHours ="http://localhost:3000/filterActivePowerByHours/"
export const filterReactivePowerByHours ="http://localhost:3000/filterReactivePowerByHours/"
export const filterApparentePowerByHours ="http://localhost:3000/filterApparentePowerByHours/"
export const filterRepartitionEnergetiqueJourMinMaxMoyen ="http://localhost:3000/filterRepartitionEnergetiqueJourMinMaxMoyen/"
export const filterRepartitionEnergetiqueMoisMinMaxMoyen ="http://localhost:3000/filterRepartitionEnergetiqueMoisMinMaxMoyen/"
export const filterRepartitionActiveEnergyMois ="http://localhost:3000/filterRepartitionActiveEnergyMois/"
export const filterRepartitionReactiveEnergyMois ="http://localhost:3000/filterRepartitionReactiveEnergyMois/"
export const filterEnergyNuit ="http://localhost:3000/filterEnergyNuit/"
export const filterEnergyJour ="http://localhost:3000/filterEnergyJour/"
export const filterRepartitionEnergetiqueMois ="http://localhost:3000/filterRepartitionEnergetiqueMois/"
export const filterRepartitionEnergetiqueJournaliere ="http://localhost:3000/filterRepartitionEnergetiqueJournaliere/"
export const filterRepartitionPowerJourMinMaxMoyen ="http://localhost:3000/filterRepartitionPowerJourMinMaxMoyen/"
export const filterEnergyHeurePointe ="http://localhost:3000/filterEnergyHeurePointe/"
export const filterEnergyHeurePleine ="http://localhost:3000/filterEnergyHeurePleine/"
export const filterEnergyHeureCreuse ="http://localhost:3000/filterEnergyHeureCreuse/"
export const filterEnergyHeureSuperPointe ="http://localhost:3000/filterEnergyHeureSuperPointe/"


export const filterRepartitionEnergieApparenteTotalMois ="http://localhost:3000/filterRepartitionEnergieApparenteTotalMois/"
export const filterRepartitionEnergieActiveTotalMois ="http://localhost:3000/filterRepartitionEnergieActiveTotalMois/"
export const filterRepartitionEnergieReactiveTotalMois ="http://localhost:3000/filterRepartitionEnergieReactiveTotalMois/"
export const filterRepartitionEnergetiqueJournaliereTotal ="http://localhost:3000/filterRepartitionEnergetiqueJournaliereTotal/"
export const filterEnergyHeurePointeTotal ="http://localhost:3000/filterEnergyHeurePointeTotal/"
export const filterEnergyHeurePleineTotal ="http://localhost:3000/filterEnergyHeurePleineTotal/"
export const filterEnergyHeureCreuseTotal ="http://localhost:3000/filterEnergyHeureCreuseTotal/"
*/



//-----------------------------------------------------------------// cloud


export const resetpass ="https://newjoule.ma/api/v1/resetpass"
export const user = "https://newjoule.ma/api/v1/profil"
export const URL_LOGIN = "https://newjoule.ma/api/v1/login"
export const lastElement = "https://newjoule.ma/api/v1/lastElement/"

export const allElement ="https://newjoule.ma/api/v1/allElement/"
export const filterCurrentByHours ="https://newjoule.ma/api/v1/filterCurrentByHours/"
export const filterActivePowerByHours ="https://newjoule.ma/api/v1/filterActivePowerByHours/"
export const filterReactivePowerByHours ="https://newjoule.ma/api/v1/filterReactivePowerByHours/"
export const filterApparentePowerByHours ="https://newjoule.ma/api/v1/filterApparentePowerByHours/"
export const filterRepartitionEnergetiqueJourMinMaxMoyen ="https://newjoule.ma/api/v1/filterRepartitionEnergetiqueJourMinMaxMoyen/"
export const filterRepartitionEnergetiqueMoisMinMaxMoyen ="https://newjoule.ma/api/v1/filterRepartitionEnergetiqueMoisMinMaxMoyen/"
export const filterRepartitionActiveEnergyMois ="https://newjoule.ma/api/v1/filterRepartitionActiveEnergyMois/"
export const filterRepartitionReactiveEnergyMois ="https://newjoule.ma/api/v1/filterRepartitionReactiveEnergyMois/"
export const filterEnergyNuit ="https://newjoule.ma/api/v1/filterEnergyNuit/"
export const filterEnergyJour ="https://newjoule.ma/api/v1/filterEnergyJour/"
export const filterRepartitionEnergetiqueMois ="https://newjoule.ma/api/v1/filterRepartitionEnergetiqueMois/"
export const filterRepartitionEnergetiqueJournaliere ="https://newjoule.ma/api/v1/filterRepartitionEnergetiqueJournaliere/"
export const filterRepartitionPowerJourMinMaxMoyen ="https://newjoule.ma/api/v1/filterRepartitionPowerJourMinMaxMoyen/"
export const filterEnergyHeurePointe ="https://newjoule.ma/api/v1/filterEnergyHeurePointe/"
export const filterEnergyHeurePleine ="https://newjoule.ma/api/v1/filterEnergyHeurePleine/"
export const filterEnergyHeureCreuse ="https://newjoule.ma/api/v1/filterEnergyHeureCreuse/"
export const filterEnergyHeureSuperPointe ="https://newjoule.ma/api/v1/filterEnergyHeureSuperPointe/"



export const filterRepartitionEnergieApparenteTotalMois ="https://newjoule.ma/api/v1/filterRepartitionEnergieApparenteTotalMois/"
export const filterRepartitionEnergieActiveTotalMois ="https://newjoule.ma/api/v1/filterRepartitionEnergieActiveTotalMois/"
export const filterRepartitionEnergieReactiveTotalMois ="https://newjoule.ma/api/v1/filterRepartitionEnergieReactiveTotalMois/"
export const filterRepartitionEnergetiqueJournaliereTotal ="https://newjoule.ma/api/v1/filterRepartitionEnergetiqueJournaliereTotal/"
export const filterEnergyHeurePointeTotal ="https://newjoule.ma/api/v1/filterEnergyHeurePointeTotal/"
export const filterEnergyHeurePleineTotal ="https://newjoule.ma/api/v1/filterEnergyHeurePleineTotal/"
export const filterEnergyHeureCreuseTotal ="https://newjoule.ma/api/v1/filterEnergyHeureCreuseTotal/"

//--------------------evocon-------------------------------------

export const activeEnergyPerMonthInMegaWattHour ="https://newjoule.ma/api/v1/activeEnergyPerMonthInMegaWattHour/"
export const peakHourEnergyInMegaWattHour ="https://newjoule.ma/api/v1/peakHourEnergyInMegaWattHour/"
export const fullHourEnergyInMegaWattHour ="https://newjoule.ma/api/v1/fullHourEnergyInMegaWattHour/"
export const offPeakHourEnergyInMegaWattHour ="https://newjoule.ma/api/v1/offPeakHourEnergyInMegaWattHour/"






//---------------------------------------------sur cloud
/*
export const resetpass ="https://apinewjouleonee.herokuapp.com/resetpass"
export const user = "https://apinewjouleonee.herokuapp.com/profil"
export const URL_LOGIN = "https://apinewjouleonee.herokuapp.com/login"
export const lastElement = "https://apinewjouleonee.herokuapp.com/lastElement/"


export const allElement ="https://apinewjouleonee.herokuapp.com/allElement/"
export const etatPorte ="https://apinewjouleonee.herokuapp.com/etatPorte/"
export const filterCurrentByHours ="https://apinewjouleonee.herokuapp.com/filterCurrentByHours/"
export const filterActivePowerByHours ="https://apinewjouleonee.herokuapp.com/filterActivePowerByHours/"
export const filterReactivePowerByHours ="https://apinewjouleonee.herokuapp.com/filterReactivePowerByHours/"
export const filterApparentePowerByHours ="https://apinewjouleonee.herokuapp.com/filterApparentePowerByHours/"
export const filterRepartitionEnergetiqueJournaliere ="https://apinewjouleonee.herokuapp.com/filterRepartitionEnergetiqueJournaliere/"
*/






































//-----------a supprimé

/*
export const filterRepartitionEnergetiqueJour ="http://localhost:3000/filterRepartitionEnergetiqueJour/"
export const filterEnergyNuit ="http://localhost:3000/filterEnergyNuit/"
export const filterEnergyJour ="http://localhost:3000/filterEnergyJour/"
export const filterRepartitionEnergetiqueMois ="http://localhost:3000/filterRepartitionEnergetiqueMois/"
export const filterMoyenneMensuelleDynamique ="http://localhost:3000/filterMoyenneMensuelleDynamique/"
export const filterMedianeMensuelleDynamique ="http://localhost:3000/filterMedianeMensuelleDynamique/"
export const filterRepartitionEnergetiqueJourMinMax ="http://localhost:3000/filterRepartitionEnergetiqueJourMinMax/"
*/

/*
export const resetpass ="https://apinewjoulelabomagbureauverita.herokuapp.com/resetpass"
export const user = "https://apinewjoulelabomagbureauverita.herokuapp.com/profil"
export const URL_LOGIN = "https://apinewjoulelabomagbureauverita.herokuapp.com/login"
export const lastElement = "https://apinewjoulelabomagbureauverita.herokuapp.com/lastElement"
export const filterRepartitionEnergetiqueJour ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterRepartitionEnergetiqueJour/"
export const filterEnergyNuit ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterEnergyNuit/"
export const filterEnergyJour ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterEnergyJour/"
export const filterCurrentByHours ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterCurrentByHours/"
export const filterRepartitionEnergetiqueMois ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterRepartitionEnergetiqueMois/"
export const filterMoyenneMensuelleDynamique ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterMoyenneMensuelleDynamique/"
export const filterMedianeMensuelleDynamique ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterMedianeMensuelleDynamique/"
export const filterRepartitionEnergetiqueJourMinMax ="https://apinewjoulelabomagbureauverita.herokuapp.com/filterRepartitionEnergetiqueJourMinMax/"
*/



//-----------------------------------------------------------------// sur cloud
/*
export const resetpass ="https://app.newjoule.com/api/v1/resetpass"
export const user = "https://app.newjoule.com/api/v1/profil"
export const URL_LOGIN = "https://app.newjoule.com/api/v1/login"
export const lastElement = "https://app.newjoule.com/api/v1/lastElement"
export const filterRepartitionEnergetiqueJour ="https://app.newjoule.com/api/v1/filterRepartitionEnergetiqueJour/"
export const filterEnergyNuit ="https://app.newjoule.com/api/v1/filterEnergyNuit/"
export const filterEnergyJour ="https://app.newjoule.com/api/v1/filterEnergyJour/"
export const filterCurrentByHours ="https://app.newjoule.com/api/v1/filterCurrentByHours/"
export const filterRepartitionEnergetiqueMois ="https://app.newjoule.com/api/v1/filterRepartitionEnergetiqueMois/"
export const filterMoyenneMensuelleDynamique ="https://app.newjoule.com/api/v1/filterMoyenneMensuelleDynamique/"
export const filterMedianeMensuelleDynamique ="https://app.newjoule.com/api/v1/filterMedianeMensuelleDynamique/"
export const filterRepartitionEnergetiqueJourMinMax ="https://app.newjoule.com/api/v1/filterRepartitionEnergetiqueJourMinMax/"

*/
