import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashbord.scss";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  homePageInfo,
  energieApparenteMoisTotal13coll,
  energieActiveMoisTotal13coll,
  energieReactiveMoisTotal13coll,
  energieheurePointeTotal13coll,
  energieheurePleineTotal13coll,
  energieheureCreuseTotal13coll,
  energiejournaliereTotal13coll

} from "../Contexts/Fonction";
import { AiFillPieChart } from "react-icons/ai";
import { borders } from "@mui/system";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "react-bootstrap/Table";
import { Divider } from "@mui/material";

//--------------
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";


import CircularProgress from '@mui/material/CircularProgress';





//-------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Dashbord = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const onemonth = new Date();
  onemonth.setDate(today.getDate() - 30);
  const onemonthISO = onemonth.toISOString();

  const [startDatetrache, setStartDatetrache] = useState(onemonthISO);
  const [stopDatetrache, setStopDatetrache] = useState(new Date());

  const [Schneider_1, setSchneider_1] = useState([]);
  const [Schneider_2, setSchneider_2] = useState([]);
  const [Schneider_3, setSchneider_3] = useState([]);
  const [Schneider_4, setSchneider_4] = useState([]);
  const [Schneider_5, setSchneider_5] = useState([]);
  const [Schneider_6, setSchneider_6] = useState([]);
  const [Schneider_7, setSchneider_7] = useState([]);
  const [Schneider_8, setSchneider_8] = useState([]);
  const [Schneider_9, setSchneider_9] = useState([]);
  const [Schneider_10, setSchneider_10] = useState([]);
  const [Schneider_11, setSchneider_11] = useState([]);
  const [Schneider_12, setSchneider_12] = useState([]);
  const [Schneider_13, setSchneider_13] = useState([]);

  const [energieApparenteTotal, setenergieApparenteTotal] = useState([]);
  const [energieActiveTotal, setenergieActiveTotal] = useState([]);
  const [energieReactiveTotal, setenergieReactiveTotal] = useState([]);

  const [energieHeurePointeTotal, setenergieHeurePointeTotal] = useState([]);
  const [energieHeurePleineTotal, setenergieHeurePleineTotal] = useState([]);
  const [energieHeureCreuseTotal, setenergieHeureCreuseTotal] = useState([]);
  const [energiejournaliereTotal, setenergiejournaliereTotal] = useState([]);

  const [isLoading, setIsLoading] = useState(true);


  const [Energie, setEnergie] = React.useState(`180`);
  const handleChangeEnergie = (event, newValue) => {
    setEnergie(newValue);
  };

  useEffect(() => {

    energieApparenteMoisTotal13coll(setenergieApparenteTotal, Energie)
    energieActiveMoisTotal13coll(setenergieActiveTotal, Energie)
    energieReactiveMoisTotal13coll(setenergieReactiveTotal, Energie)
    energieheurePointeTotal13coll(setenergieHeurePointeTotal, startDatetrache, stopDatetrache)
    energieheurePleineTotal13coll(setenergieHeurePleineTotal, startDatetrache, stopDatetrache)
    energieheureCreuseTotal13coll(setenergieHeureCreuseTotal, startDatetrache, stopDatetrache)
    energiejournaliereTotal13coll(setenergiejournaliereTotal, Energie)

    const interval = setInterval(() => {
      energieApparenteMoisTotal13coll(setenergieApparenteTotal, Energie)
      energieActiveMoisTotal13coll(setenergieActiveTotal, Energie)
      energieReactiveMoisTotal13coll(setenergieReactiveTotal, Energie)
      energieheurePointeTotal13coll(setenergieHeurePointeTotal, startDatetrache, stopDatetrache)
      energieheurePleineTotal13coll(setenergieHeurePleineTotal, startDatetrache, stopDatetrache)
      energieheureCreuseTotal13coll(setenergieHeureCreuseTotal, startDatetrache, stopDatetrache)
      energiejournaliereTotal13coll(setenergiejournaliereTotal, Energie)

    }, 300000); //5min 60000
    return () => {
      clearInterval(interval);
    };
  }, [Energie, Schneider_1, Schneider_2, Schneider_3, Schneider_4, Schneider_5, Schneider_6, Schneider_7, Schneider_8, Schneider_9, Schneider_10, Schneider_11, Schneider_12, Schneider_13, startDatetrache, stopDatetrache,
  ]);

  useEffect(() => {
    [setSchneider_1, setSchneider_2, setSchneider_3, setSchneider_4, setSchneider_5, setSchneider_6, setSchneider_7, setSchneider_8, setSchneider_9, setSchneider_10, setSchneider_11, setSchneider_12, setSchneider_13,
    ].map((element, index) => {
      homePageInfo(element, index + 1);
    });
    const interval = setInterval(() => {
      [setSchneider_1, setSchneider_2, setSchneider_3, setSchneider_4, setSchneider_5, setSchneider_6, setSchneider_7, setSchneider_8, setSchneider_9, setSchneider_10, setSchneider_11, setSchneider_12, setSchneider_13,
      ].map((element, index) => {
        homePageInfo(element, index + 1);
      });
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setenergieApparenteTotal(energieActiveTotal);
      setIsLoading(false);
    }, 2000);
  }, []);
  const actions = [
    { color: "Dh", name: "H.Pointe", prix: Number(energieHeurePointeTotal.prix).toLocaleString('fr-FR') },
    { color: "Dh", name: "H.Creuse", prix: Number(energieHeureCreuseTotal.prix).toLocaleString('fr-FR') },
    { color: "Dh", name: "H.Pleine", prix: Number(energieHeurePleineTotal.prix).toLocaleString('fr-FR') },
    { color: "Dh", name: "Total", prix: (Number(energieHeurePointeTotal.prix) + Number(energieHeureCreuseTotal.prix) + Number(energieHeurePleineTotal.prix)).toLocaleString('fr-FR') },];



  return (
    <div className="container-flex row justify-content-md-center mt-5">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 1, md: 1, lg: 12 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Item className="borderBottom2">
              <div className="mb-2">Puissance active totale</div>
              <div>
                <span className="fs-2 fw-bold">
                  {" "}
                  <ExpandLessIcon className="c2 fs-1" />{" "}
                </span>
                <span className="fw-bold fs-3">
                  {Number(
                    Number(Schneider_1?.active_power ?? 0) +
                    Number(Schneider_2?.active_power ?? 0) +
                    Number(Schneider_3?.active_power ?? 0) +
                    Number(Schneider_4?.active_power ?? 0) +
                    Number(Schneider_5?.active_power ?? 0) +
                    Number(Schneider_6?.active_power ?? 0) +
                    Number(Schneider_7?.active_power ?? 0) +
                    Number(Schneider_8?.active_power ?? 0) +
                    Number(Schneider_9?.active_power ?? 0) +
                    Number(Schneider_10?.active_power ?? 0) +
                    Number(Schneider_11?.active_power ?? 0) +
                    Number(Schneider_12?.active_power ?? 0) +
                    Number(Schneider_13?.active_power ?? 0)).toFixed(1)}
                </span>{" "}
                Kw
              </div>
              <div className="d-flex justify-content-around mt-3">
                <span>
                  P1:{" "}
                  {Number(
                  Number(Schneider_1?.active_power_L1 ?? 0)+
                  Number(Schneider_2?.active_power_L1 ?? 0)+
                  Number(Schneider_3?.active_power_L1 ?? 0)+
                  Number(Schneider_4?.active_power_L1 ?? 0)+
                  Number(Schneider_5?.active_power_L1 ?? 0)+
                  Number(Schneider_6?.active_power_L1 ?? 0)+
                  Number(Schneider_7?.active_power_L1 ?? 0)+
                  Number(Schneider_8?.active_power_L1 ?? 0)+
                  Number(Schneider_9?.active_power_L1 ?? 0)+
                  Number(Schneider_10?.active_power_L1 ?? 0)+
                  Number(Schneider_11?.active_power_L1 ?? 0)+
                  Number(Schneider_12?.active_power_L1 ?? 0)+
                  Number(Schneider_13?.active_power_L1 ?? 0)
                  ).toFixed(1)}
                </span>
                <span>
                  P2:{" "}
                  {Number(
                  Number(Schneider_1?.active_power_L2 ?? 0)+
                  Number(Schneider_2?.active_power_L2 ?? 0)+
                  Number(Schneider_3?.active_power_L2 ?? 0)+
                  Number(Schneider_4?.active_power_L2 ?? 0)+
                  Number(Schneider_5?.active_power_L2 ?? 0)+
                  Number(Schneider_6?.active_power_L2 ?? 0)+
                  Number(Schneider_7?.active_power_L2 ?? 0)+
                  Number(Schneider_8?.active_power_L2 ?? 0)+
                  Number(Schneider_9?.active_power_L2 ?? 0)+
                  Number(Schneider_10?.active_power_L2 ?? 0)+
                  Number(Schneider_11?.active_power_L2 ?? 0)+
                  Number(Schneider_12?.active_power_L2 ?? 0)+
                  Number(Schneider_13?.active_power_L2 ?? 0)
                  ).toFixed(1)}
                </span>
                <span>
                  P3:{" "}
                  {Number(
                  Number(Schneider_1?.active_power_L3 ?? 0)+
                  Number(Schneider_2?.active_power_L3 ?? 0)+
                  Number(Schneider_3?.active_power_L3 ?? 0)+
                  Number(Schneider_4?.active_power_L3 ?? 0)+
                  Number(Schneider_5?.active_power_L3 ?? 0)+
                  Number(Schneider_6?.active_power_L3 ?? 0)+
                  Number(Schneider_7?.active_power_L3 ?? 0)+
                  Number(Schneider_8?.active_power_L3 ?? 0)+
                  Number(Schneider_9?.active_power_L3 ?? 0)+
                  Number(Schneider_10?.active_power_L3 ?? 0)+
                  Number(Schneider_11?.active_power_L3 ?? 0)+
                  Number(Schneider_12?.active_power_L3 ?? 0)+
                  Number(Schneider_13?.active_power_L3 ?? 0)
                  ).toFixed(1)}
                </span>
              </div>
            </Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Item className="borderBottom3">
              <div className="mb-2">Puissance reactive totale</div>
              <div>
                <span className="fs-2 fw-bold">
                  {" "}
                  <ExpandLessIcon className="c3 fs-1" />{" "}
                </span>
                <span className="fw-bold fs-3">
                  {Number(
                  Number(Schneider_1?.reactive_power ?? 0)+
                  Number(Schneider_2?.reactive_power ?? 0)+
                  Number(Schneider_3?.reactive_power ?? 0)+
                  Number(Schneider_4?.reactive_power ?? 0)+
                  Number(Schneider_5?.reactive_power ?? 0)+
                  Number(Schneider_6?.reactive_power ?? 0)+
                  Number(Schneider_7?.reactive_power ?? 0)+
                  Number(Schneider_8?.reactive_power ?? 0)+
                  Number(Schneider_9?.reactive_power ?? 0)+
                  Number(Schneider_10?.reactive_power ?? 0)+
                  Number(Schneider_11?.reactive_power ?? 0)+
                  Number(Schneider_12?.reactive_power ?? 0)+
                  Number(Schneider_13?.reactive_power ?? 0)
                  ).toFixed(1)}
                </span>{" "}
                Kvar
              </div>
              <div className="d-flex justify-content-around mt-3">
                <span>
                  Q1:{" "}
                  {Number(
                  Number(Schneider_1?.reactive_power_L1 ?? 0)+
                  Number(Schneider_2?.reactive_power_L1 ?? 0)+
                  Number(Schneider_3?.reactive_power_L1 ?? 0)+
                  Number(Schneider_4?.reactive_power_L1 ?? 0)+
                  Number(Schneider_5?.reactive_power_L1 ?? 0)+
                  Number(Schneider_6?.reactive_power_L1 ?? 0)+
                  Number(Schneider_7?.reactive_power_L1 ?? 0)+
                  Number(Schneider_8?.reactive_power_L1 ?? 0)+
                  Number(Schneider_9?.reactive_power_L1 ?? 0)+
                  Number(Schneider_10?.reactive_power_L1 ?? 0)+
                  Number(Schneider_11?.reactive_power_L1 ?? 0)+
                  Number(Schneider_12?.reactive_power_L1 ?? 0)+
                  Number(Schneider_13?.reactive_power_L1 ?? 0)
                  ).toFixed(1)}
                </span>
                <span>
                  Q2:{" "}
                  {Number(
                  Number(Schneider_1?.reactive_power_L2 ?? 0)+
                  Number(Schneider_2?.reactive_power_L2 ?? 0)+
                  Number(Schneider_3?.reactive_power_L2 ?? 0)+
                  Number(Schneider_4?.reactive_power_L2 ?? 0)+
                  Number(Schneider_5?.reactive_power_L2 ?? 0)+
                  Number(Schneider_6?.reactive_power_L2 ?? 0)+
                  Number(Schneider_7?.reactive_power_L2 ?? 0)+
                  Number(Schneider_8?.reactive_power_L2 ?? 0)+
                  Number(Schneider_9?.reactive_power_L2 ?? 0)+
                  Number(Schneider_10?.reactive_power_L2 ?? 0)+
                  Number(Schneider_11?.reactive_power_L2 ?? 0)+
                  Number(Schneider_12?.reactive_power_L2 ?? 0)+
                  Number(Schneider_13?.reactive_power_L2 ?? 0)
                  ).toFixed(1)}
                </span>
                <span>
                  Q3:{" "}
                  {Number(
                  Number(Schneider_1?.reactive_power_L3 ?? 0)+
                  Number(Schneider_2?.reactive_power_L3 ?? 0)+
                  Number(Schneider_3?.reactive_power_L3 ?? 0)+
                  Number(Schneider_4?.reactive_power_L3 ?? 0)+
                  Number(Schneider_5?.reactive_power_L3 ?? 0)+
                  Number(Schneider_6?.reactive_power_L3 ?? 0)+
                  Number(Schneider_7?.reactive_power_L3 ?? 0)+
                  Number(Schneider_8?.reactive_power_L3 ?? 0)+
                  Number(Schneider_9?.reactive_power_L3 ?? 0)+
                  Number(Schneider_10?.reactive_power_L3 ?? 0)+
                  Number(Schneider_11?.reactive_power_L3 ?? 0)+
                  Number(Schneider_12?.reactive_power_L3 ?? 0)+
                  Number(Schneider_13?.reactive_power_L3 ?? 0)
                  ).toFixed(1)}
                </span>
              </div>
            </Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Item className="borderBottom4">
              <div className="mb-2">Puissance apparente totale</div>
              <div>
                <span className="fs-2 fw-bold">
                  {" "}
                  <ExpandLessIcon className="c4 fs-1" />{" "}
                </span>
                <span className="fw-bold fs-3">
                  {Number(
                  Number(Schneider_1?.apparente_power ?? 0)+
                  Number(Schneider_2?.apparente_power ?? 0)+
                  Number(Schneider_3?.apparente_power ?? 0)+
                  Number(Schneider_4?.apparente_power ?? 0)+
                  Number(Schneider_5?.apparente_power ?? 0)+
                  Number(Schneider_6?.apparente_power ?? 0)+
                  Number(Schneider_7?.apparente_power ?? 0)+
                  Number(Schneider_8?.apparente_power ?? 0)+
                  Number(Schneider_9?.apparente_power ?? 0)+
                  Number(Schneider_10?.apparente_power ?? 0)+
                  Number(Schneider_11?.apparente_power ?? 0)+
                  Number(Schneider_12?.apparente_power ?? 0)+
                  Number(Schneider_13?.apparente_power ?? 0)
                  ).toFixed(1)}
                </span>{" "}
                Kva
              </div>
              <div className="d-flex justify-content-around mt-3">
                <span>
                  S1:{" "}
                  {Number(
                  Number(Schneider_1?.apparent_power_L1 ?? 0)+
                  Number(Schneider_2?.apparent_power_L1 ?? 0)+
                  Number(Schneider_3?.apparent_power_L1 ?? 0)+
                  Number(Schneider_4?.apparent_power_L1 ?? 0)+
                  Number(Schneider_5?.apparent_power_L1 ?? 0)+
                  Number(Schneider_6?.apparent_power_L1 ?? 0)+
                  Number(Schneider_7?.apparent_power_L1 ?? 0)+
                  Number(Schneider_8?.apparent_power_L1 ?? 0)+
                  Number(Schneider_9?.apparent_power_L1 ?? 0)+
                  Number(Schneider_10?.apparent_power_L1 ?? 0)+
                  Number(Schneider_11?.apparent_power_L1 ?? 0)+
                  Number(Schneider_12?.apparent_power_L1 ?? 0)+
                  Number(Schneider_13?.apparent_power_L1?? 0)
                  ).toFixed(1)}
                </span>
                <span>
                  S2:{" "}
                  {Number(
                  Number(Schneider_1?.apparent_power_L2 ?? 0)+
                  Number(Schneider_2?.apparent_power_L2 ?? 0)+
                  Number(Schneider_3?.apparent_power_L2 ?? 0)+
                  Number(Schneider_4?.apparent_power_L2 ?? 0)+
                  Number(Schneider_5?.apparent_power_L2 ?? 0)+
                  Number(Schneider_6?.apparent_power_L2 ?? 0)+
                  Number(Schneider_7?.apparent_power_L2 ?? 0)+
                  Number(Schneider_8?.apparent_power_L2 ?? 0)+
                  Number(Schneider_9?.apparent_power_L2 ?? 0)+
                  Number(Schneider_10?.apparent_power_L2 ?? 0)+
                  Number(Schneider_11?.apparent_power_L2 ?? 0)+
                  Number(Schneider_12?.apparent_power_L2 ?? 0)+
                  Number(Schneider_13?.apparent_power_L2 ?? 0)
                  ).toFixed(1)}
                </span>
                <span>
                  S3:{" "}
                  {Number(
                  Number(Schneider_1?.apparent_power_L3 ?? 0)+
                  Number(Schneider_2?.apparent_power_L3 ?? 0)+
                  Number(Schneider_3?.apparent_power_L3 ?? 0)+
                  Number(Schneider_4?.apparent_power_L3 ?? 0)+
                  Number(Schneider_5?.apparent_power_L3 ?? 0)+
                  Number(Schneider_6?.apparent_power_L3 ?? 0)+
                  Number(Schneider_7?.apparent_power_L3 ?? 0)+
                  Number(Schneider_8?.apparent_power_L3 ?? 0)+
                  Number(Schneider_9?.apparent_power_L3 ?? 0)+
                  Number(Schneider_10?.apparent_power_L3 ?? 0)+
                  Number(Schneider_11?.apparent_power_L3 ?? 0)+
                  Number(Schneider_12?.apparent_power_L3 ?? 0)+
                  Number(Schneider_13?.apparent_power_L3 ?? 0)
                  ).toFixed(1)}
                </span>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box className="mt-4" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Item>
              <Box
                sx={{ width: "100%" }}
                className="mb-4 d-flex justify-content-around"
              >
                <span className="fw-bold fs-5">Energie totale </span>
                <Tabs
                  value={Energie}
                  onChange={handleChangeEnergie}
                  aria-label="wrapped label tabs example"
                >
                  <Tab value="90" label="3 mois" wrapped />
                  <Tab value="180" label="6 mois" />
                  <Tab value="366" label="1 an" />
                </Tabs>
              </Box>
              <Box className="ms-5 fw-bold">
                Facteur de puissance global :{" "}
                {(Number(
                  Number(Schneider_1?.active_power ?? 0)+
                    Number(Schneider_2?.active_power ?? 0)+
                    Number(Schneider_3?.active_power ?? 0)+
                    Number(Schneider_4?.active_power ?? 0)+
                    Number(Schneider_5?.active_power ?? 0)+
                    Number(Schneider_6?.active_power ?? 0)+
                    Number(Schneider_7?.active_power ?? 0)+
                    Number(Schneider_8?.active_power ?? 0)+
                    Number(Schneider_9?.active_power ?? 0)+
                    Number(Schneider_10?.active_power ?? 0)+
                    Number(Schneider_11?.active_power ?? 0)+
                    Number(Schneider_12?.active_power ?? 0)+
                    Number(Schneider_13?.active_power ?? 0)
                  ).toFixed(1) /
                  Number(
                  Number(Schneider_1?.apparente_power ?? 0)+
                    Number(Schneider_2?.apparente_power ?? 0)+
                    Number(Schneider_3?.apparente_power ?? 0)+
                    Number(Schneider_4?.apparente_power ?? 0)+
                    Number(Schneider_5?.apparente_power ?? 0)+
                    Number(Schneider_6?.apparente_power ?? 0)+
                    Number(Schneider_7?.apparente_power ?? 0)+
                    Number(Schneider_8?.apparente_power ?? 0)+
                    Number(Schneider_9?.apparente_power ?? 0)+
                    Number(Schneider_10?.apparente_power ?? 0)+
                    Number(Schneider_11?.apparente_power ?? 0)+
                    Number(Schneider_12?.apparente_power ?? 0)+
                    Number(Schneider_13?.apparente_power ?? 0)
                  ).toFixed(1)
                ).toFixed(2)}
              </Box>

              {
                isLoading ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '362px' }}>
                    <CircularProgress />
                  </div> :

                  <Chart
                    class="ms-3 py-1"
                    options={{
                      chart: {
                        type: "bar",
                        height: 350,
                      },
                      colors: [
                        "#fc0362",
                        "#07922a",
                        "#0398fc",
                        "#8929c4",
                        "#74EFF7",
                        "#FA559F",
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                          columnWidth: "15%",
                          endingShape: "rounded",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                      },
                      xaxis: {
                        type: "datetime",
                        categories: energieActiveTotal.dates,
                      },
                      yaxis: {
                        title: {
                          text: "Energie",
                        },
                        labels: {
                          formatter: function (val) {
                            return parseFloat(val).toFixed(2);
                          },
                        },
                      },
                      fill: {
                        opacity: 1,
                      },

                      tooltip: {
                        y: {
                          formatter: function (val) {
                            return val;
                          },
                        },
                        x: {
                          format: "dd.MM.yy ",
                        },
                      },
                    }}
                    series={[
                      {
                        type: "bar",
                        name: "Energie_active (Mwh)",
                        data: energieActiveTotal.energies,
                      },
                      {
                        type: "bar",
                        name: "Energie_reactive (Mvarh)",
                        data: energieReactiveTotal.energies,
                      },
                      {
                        type: "bar",
                        name: "Energie_apparente (Mvah)",
                        data: energieApparenteTotal.energies,
                      },
                    ]}
                    width="95%"
                    height="362px"
                  // width="500"
                  />
              }

            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Item style={{ height: "500px" }}>
              <div class=" ">
                <span>
                  {" "}
                  <AiFillPieChart class="fs-3 me-2" />
                </span>
                <span className="fw-bold fs-6">
                  Repartition energetique par section
                </span>
              </div>

              {
                isLoading ?
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress />
                  </Box> :
                  <Chart
                    options={{
                      chart: {
                        width: 380,
                        type: "pie",
                      },
                      colors: [
                        "#fc0362",
                        "#07922a",
                        "#0398fc",
                        "#00FFAB",
                        "#F7D716",
                        "#97AF16",
                        "#97FF16",
                        "#000000",
                      ],
                      labels: [
                        "TGBT_8 cuisson / presse SCK_8",
                        "TGBT_11",
                        "TGBT_12",
                        "TGBT_2:Atomiseur P1_P2",
                        "TGBT_4:Rechauffeur gaz - compresseur - eclairage - station de pompage",
                        "TGBT_8:Atomiseur_8 - compresseur_9",
                        "TGBT_1:Broyeur continu",
                        "SCK_9 - PDM_9 - LEM - cuisson",
                      ],
                      legend: {
                        position: "bottom",
                        itemMargin: {
                          vertical: 4, // Ajustez cet espace vertical pour placer deux éléments par ligne
                        },
                        horizontalAlign: 'left'
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 200,
                            },
                            legend: {
                              position: "bottom",
                              itemMargin: {
                                vertical: 4, // Ajustez cet espace vertical pour placer deux éléments par ligne
                              },
                              horizontalAlign: 'left'
                            },
                          },
                        },
                      ],
                    }}
                    series={[
                      Number( Schneider_1?.Total_positive_apparent_energy ?? 0 ),
                      Number(Schneider_2?.Total_positive_apparent_energy ?? 0 ),
                      Number( Schneider_3?.Total_positive_apparent_energy ?? 0 ),
                      Number(Number(Schneider_4?.Total_positive_apparent_energy ?? 0) + Number(Schneider_5?.Total_positive_apparent_energy ?? 0)),
                      Number(
                        Number(Schneider_6?.Total_positive_apparent_energy ?? 0) +
                        Number(Schneider_11?.Total_positive_apparent_energy ?? 0) +
                        Number(Schneider_13?.Total_positive_apparent_energy ?? 0)
                      ),
                      Number(Schneider_7?.Total_positive_apparent_energy ?? 0  ),
                      Number(Number(Schneider_8?.Total_positive_apparent_energy ?? 0 ) + Number(Schneider_12?.Total_positive_apparent_energy ?? 0) ),
                      Number( (Schneider_9?.Total_positive_apparent_energy ?? 0) + (Schneider_10?.Total_positive_apparent_energy ?? 0) ),
                    ]}
                    type="pie"
                    width="100%"
                    height="100%"
                  //width="300px"
                  />
              }

            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box className="mt-4" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Item>
              <Box
                sx={{ width: "100%" }}
                className="mb-4 d-flex justify-content-around"
              >
                <span className="fw-bold fs-5">Emission CO2 par mois ( t )</span>
                <Tabs
                  value={Energie}
                  onChange={handleChangeEnergie}
                  aria-label="wrapped label tabs example"
                >
                  <Tab value="90" label="3 mois" wrapped />
                  <Tab value="180" label="6 mois" />
                  <Tab value="366" label="1 an" />
                </Tabs>
              </Box>

              {isLoading ?
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                  <CircularProgress />
                </Box> :
                <Chart
                  class="ms-3 mb-2"
                  options={{
                    chart: {
                      type: "bar",
                      height: 350,
                    },
                    colors: ["#000000"],
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "15%",
                        endingShape: "rounded",
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      show: true,
                      width: 10,
                      colors: ["transparent"],
                    },
                    xaxis: {
                      type: "datetime",
                      categories: energieActiveTotal.dates,
                    },
                    yaxis: {
                      title: {
                        text: "Co2 en tonne",
                      },
                      labels: {
                        formatter: function (val) {
                          return parseFloat(val).toFixed(2);
                        },
                      },
                    },
                    fill: {
                      opacity: 0.6,
                    },
                    tooltip: {
                      y: {
                        formatter: function (val) {
                          return val + " t";
                        },
                      },
                      x: {
                        format: "dd.MM ",
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Co2",
                      data: energieActiveTotal.co2,
                    },
                  ]}
                  type="scatter"
                  width="95%"
                  height="280px"
                // width="500"
                />
              }
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Item style={{ height: "" }}>
              <Box
                sx={{ width: "100%" }}
                className="mb-4 d-flex justify-content-around"
              >
                <span className="fw-bold fs-5">Tranche de consommation</span>
              </Box>

              <Box sx={{ width: "100%" }} className="ms-2 mb-4 mt-2">
                <div className="container d-flex justify-content-between ">
                  <input
                    className="form-control no-border mx-1"
                    type="date"
                    onChange={(date) => setStartDatetrache(date.target?.value)}
                  ></input>
                  <input
                    className="form-control no-border mx-1"
                    type="date"
                    onChange={(date) => setStopDatetrache(date.target?.value)}
                  ></input>
                </div>
              </Box>
              {isLoading ?
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '248px' }}>
                  <CircularProgress />
                </Box> :
                <Chart
                  class=""
                  options={{
                    chart: {
                      type: "bar",
                      height: 350,
                    },

                    plotOptions: {
                      bar: {
                        horizontal: true,
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      formatter: function (val) {
                        return val + " Mwh";
                      },
                    },
                    xaxis: {
                      categories: [
                        "H.Pointe",
                        "H.Pleine",
                        "H.Creuse",
                      ],
                      labels: {
                        format: "MMM/yyy",
                      },
                    },

                    yaxis: {
                      //reversed: true,

                      axisTicks: {
                        show: true,
                      } /*
                                        labels: {
                                            formatter: function (val) {
                                                let d = new Date(val);
                                                let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                                                let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
                                                //let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                                                return `${mo}-${ye}`
                                            }
                                        }*/,
                    },
                  }}
                  series={[
                    {
                      name: "E heure de pointe",
                      data: [Number(energieHeurePointeTotal.totalEnergy)],
                    },
                    {
                      name: "E heure pleine",
                      data: [Number(energieHeurePleineTotal.totalEnergy)],
                    },
                    {
                      name: "E heure creuse",
                      data: [Number(energieHeureCreuseTotal.totalEnergy)],
                    },
                  ]}
                  type="bar"
                  width="95%"
                  height="248px"
                // width="500"
                />
              }

              <Box
                sx={{ height: 1, transform: "translateZ(0px)", flexGrow: 1 }}
              >
                <Backdrop open={open} />
                <SpeedDial
                  ariaLabel="SpeedDial tooltip example"
                  sx={{ position: "absolute", bottom: -3, right: 16 }}
                  icon={<AttachMoneyIcon />}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.color}
                      tooltipTitle={action.name + ":" + action.prix}
                      tooltipOpen
                      onClick={handleClose}
                    />
                  ))}
                </SpeedDial>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box className="mt-4" sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <Item>
            <Box
              sx={{ width: "100%" }}
              className="mb-4 d-flex justify-content-around"
            >
              <span className="fw-bold fs-5">Energie journaliere </span>
              <Tabs
                value={Energie}
                onChange={handleChangeEnergie}
                aria-label="wrapped label tabs example"
              >
                <Tab value="90" label="3 mois" wrapped />
                <Tab value="180" label="6 mois" />
                <Tab value="366" label="1 an" />
              </Tabs>
            </Box>

            {isLoading ?
              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '355px' }}>
                <CircularProgress />
              </Box> :
              <Chart
                class="ms-3 py-1"
                options={{
                  chart: {
                    type: "bar",
                    height: 350,
                  },
                  colors: [
                    "#fc0362",
                    "#07922a",
                    "#0398fc",
                    "#8929c4",
                    "#74EFF7",
                    "#FA559F",
                  ],
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "15%",
                      endingShape: "rounded",
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  xaxis: {
                    type: "datetime",
                    categories: energiejournaliereTotal.dates,
                  },
                  yaxis: {
                    title: {
                      text: "Energie",
                    },
                    labels: {
                      formatter: function (val) {
                        return parseFloat(val).toFixed(2);
                      },
                    },
                  },
                  fill: {
                    opacity: 1,
                  },

                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return val;
                      },
                    },
                    x: {
                      format: "dd.MM.yy ",
                    },
                  },
                }}
                series={[
                  {
                    type: "bar",
                    name: "Energie_active (Mwh)",
                    data: energiejournaliereTotal.energies,
                  },

                ]}
                width="95%"
                height="362px"
              // width="500"
              />
            }
          </Item>
        </Grid>

      </Box>
    </div>
  );
};

export default Dashbord;

