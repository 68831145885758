import { Outlet } from "react-router-dom";
import Authentification from "../Page/Authentification";
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode'


/*
const useAuth=()=>{
    const user = {loggedIn:true}
    return user && user.loggedIn;
};
*/

/*
const isAuthenticated = (setToken) => {
    const token = window.localStorage.getItem('token')
    const { exp } = jwtDecode(token)
    if (token) {
        if (exp * 1000 > new Date().getTime()) {
            setToken(token)
            //return token
        }
    }

}
*/


const isAuthenticated = (setToken) => {
    if ( window.localStorage.getItem('token')) {
        const token = window.localStorage.getItem('token')
        const { exp } = jwtDecode(token)
        if (exp * 1000 > new Date().getTime()) {
            setToken(token)
            //return token
        }
    }

}

const ProtectedRoute = () => {

    const [token, setToken] = useState();
    
    useEffect(()=>{
        isAuthenticated(setToken)
    },[])
    
 

    return token ? <Outlet /> : <Authentification setToken={setToken} />

    //const isAuth = useAuth();
    //return isAuth ? <Outlet/> : <Authentification/>

}

export default ProtectedRoute;
