import React, { useEffect, useState } from "react";
import axios from 'axios';
import { lastElement } from "../Config";
import Header from "../Contexts/Header";


import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';





const energieConsomme = (alerte, depassement) => {
    axios.get(lastElement, Header()).then((response) => {
        var t1 = Number(response.data.total_0)
        var t2 = Number(response.data.total_1)
        var t3 = Number(response.data.total_2)
        var t = (t1 + t2 + t3) * 0.001

        var energie_ = t
        //  console.log(energie_)
        var v = ''
        var a = `vous n'avez pas atteint 75% de la tranche `
        if (energie_ <= 100) {
            if (energie_ >= 75) { a = `vous avez depassé 75% de tranche 1` }
            v = `vous etes dans la tranche 1`
        }
        if (energie_ > 100 && energie_ <= 150) {
            v = (energie_ - 101) * 2
            if (v >= 75) { a = `vous avez depassé 75% de tranche 2` }
            v = `vous avez depassé la tranche 1 de ${v} %`
        }
        if (energie_ > 150 && energie_ <= 200) {
            v = (energie_ - 151) * 2
            if (v >= 75) { a = `vous avez depassé 75% de tranche 3` }
            v = `vous avez depassé la tranche 2 de ${v} %`
        }
        if (energie_ > 200 && energie_ <= 300) {
            v = (energie_ - 201)
            if (v >= 75) { a = `vous avez depassé 75% de tranche 4` }
            v = `vous avez depassé la tranche 3 de ${v} %`
        }
        if (energie_ > 300 && energie_ <= 500) {
            v = (energie_ - 301) / 2
            if (v >= 75) { a = `vous avez depassé 75% de tranche 5` }
            v = `vous avez depassé la tranche 4 de ${v} %`
        }
        if (energie_ > 500) { v = `vous avez depassé la tranche 5` }


        alerte(v)
        depassement(a)

        //console.log(a)

    });
}

const Alertes = () => {

    const [alerte, setalerte] = useState([]);
    const [depassement, setdepassement] = useState([]);

    useEffect(() => {
        energieConsomme(setalerte, setdepassement)

    }, [])

    return (
        <div className="container mt-5">
            
            
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="outlined" severity="error">
                {alerte}  !
                </Alert>
                <Alert variant="outlined" severity="warning">
                {depassement}  !
                </Alert>
                
            </Stack>
        </div>
    );
}

export default Alertes;